<template v-if="config">
  <div
    class="col1"
    :class="{ fullwidth: config?.size == 0, normal: config?.size > 0 }"
  >

    <!-- :style="{ margin: config?.size === '1' ? '50px 32px 0px 32px' : '50px 0px 0px 0px' }" -->
    <template v-if="config?.size == 0">
      <div class="container-videos">
        <component
          :style="{
            'aspect-ratio':
              mediaType === 'video' ? aspectRatios[config?.ratio] : 'unset',
          }"
          :poster="media['preview1']"
          :is="mediaType"
          :src="media['player_html1']"
          :muted="false"
          playsinline=""
          class="media"
          :class="{ fullwidth: config.size == 0 }"
          :id="`video-player-${id}-1`"
          @ended="reset(id)"
          preload="metadata"
        >
        </component>
        <span
          v-if="mediaType === 'video'"
          @click="toggle(id)"
          class="controle"
          :data-text="
            config.watch_button1 == null ? 'watch' : config.watch_button1
          "
          :class="{ hidden: hidden, play: !playing, pause: playing }"
        ></span>
      </div>
    </template>
    <template v-for="(item, key) in media" :key="key">
      <div
        class="container-videos"
        v-if="item !== '' && key.startsWith('player_html') && config?.size > 0"
      >
        <template v-for="value in 3" :key="value">
          <component
            :style="{
              'aspect-ratio':
                mediaType === 'video' ? aspectRatios[config?.ratio] : 'unset',
            }"
            v-if="key == ['player_html' + value]"
            :poster="media['preview' + value]"
            :is="mediaType"
            :src="media['player_html' + value]"
            :muted="false"
            playsinline=""
            class="media"
            :id="`video-player-${id + '-' + value}`"
            @ended="reset(value + id)"
            preload="metadata"
          >
          </component>
          <span
            v-if="
              mediaType === 'video' && key == ['player_html1'] && item !== ''
            "
            @click="toggle(id)"
            class="controle"
            :data-text="
              config.watch_button1 == null ? 'watch' : config.watch_button1
            "
            :class="{ hidden: hidden, play: !playing, pause: playing }"
          ></span>
          <span
            v-if="
              mediaType === 'video' && key == ['player_html2'] && item !== ''
            "
            @click="toggle2(id)"
            class="controle"
            :data-text="
              config.watch_button2 == null ? 'watch' : config.watch_button2
            "
            :class="{ hidden: hidden, play: !playing2, pause: playing2 }"
          >
          </span>
          <span
            v-if="
              mediaType === 'video' && key == ['player_html3'] && item !== ''
            "
            @click="toggle3(id)"
            class="controle"
            :data-text="
              config.watch_button3 == null ? 'watch' : config.watch_button3
            "
            :class="{ hidden: hidden, play: !playing3, pause: playing3 }"
          ></span>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";

const isVideo = require("is-video");

export default {
  name: "MediaReader",
  props: {
    id: Number,
    media: Object,
    config: Object,
  },
  setup(props) {
    const aspectRatios = ["16/9", "4/3", "9/16"];
    const playing = ref(false);
    function toggle(id) {
      const video = document.getElementById(`video-player-${id}-1`);
      if (playing.value) video.pause();
      else video.play();
      playing.value = !playing.value;
    }

    const playing2 = ref(false);
    function toggle2(id) {
      const video = document.getElementById(`video-player-${id}-2`);
      if (playing2.value) video.pause();
      else video.play();
      playing2.value = !playing2.value;
    }

    const playing3 = ref(false);
    function toggle3(id) {
      const video = document.getElementById(`video-player-${id}-3`);
      if (playing3.value) video.pause();
      else video.play();
      playing3.value = !playing3.value;
    }

    function reset(id) {
      const video = document.getElementById(`video-player-${id}`);
      playing.value = false;
      video.currentTime = 0;
      video.load();
    }

    // const isSVG = computed(() => props.media["player_html1"].split('.').pop() === 'svg')
    const isSVG = computed(() => !isVideo(props.media["player_html1"]));

    return {
      mediaType: isSVG.value ? "img" : "video",
      hidden: isSVG.value,
      playing,
      toggle,
      playing2,
      toggle2,
      playing3,
      toggle3,
      reset,
      aspectRatios,
    };
  },
};
</script>

<style lang="scss" scoped>
body.dark-mode {
  .play {
    &::after {
      filter: invert(0) !important;
    }
  }
}
.col1 {
  flex-grow: 1;
  height: fit-content;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 0;
  margin-top: 0;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
  &.normal {
    margin: 50px 32px 0;
  }
  &.fullwidth {
    margin: 0;
    padding-top: 50px;
  }
  .controle {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 60px;
    background-color: white;
    margin: 0;
    border-radius: 0;
    cursor: pointer;
    &::before {
      position: absolute;
      content: "";
      display: inline-block;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      //transition: all 0.3s cubic-bezier(0.87, 0, 0.13, 1);
    }
    &.play {
      &::before {
        height: 0;
        width: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 14px solid #000;
      }
      &::after {
        //;
        content: attr(data-text); //"watch";
        color: var(--darker);
        filter: invert(1);
        background-color: transparent;
        position: absolute;
        right: 0;
        top: 45%;
        padding-left: 16px;
        font-size: 21px;
        font-weight: 700;
        transform: translate(100%, -50%);
      }
    }
    &.pause {
      &::before {
        width: 4px;
        height: 16px;
        // border: 0px solid var(--darker);
        border-left: 4px solid #000;
        border-right: 4px solid #000;
        border-top: 0px solid transparent;
        border-bottom: 0px solid transparent;
      }
      &::after {
        content: "pause";
        color: var(--darker);
        filter: invert(1);
        background-color: transparent;
        position: absolute;
        right: 0;
        top: 45%;
        padding-left: 16px;
        font-size: 21px;
        font-weight: 700;
        transform: translate(100%, -50%);
      }
    }
    &.hidden {
      display: none;
    }
  }
  .media {
    width: 100%;
    background: url("../../assets/poster.png");
  }
}
.container-videos {
  position: relative;
  margin-right: 20px;
  align-self: center;
  flex: 1;
  video {
    object-fit: cover;
  }
  @media screen and (max-width: 1200px) {
    margin-right: 0;
    width: 100%;
    &:first-child {
      margin-top: 0;
    }
    margin-top: 20px;
  }
  &:last-child {
    margin-right: 0;
  }
}

</style>
