<template>
	<div id="components">
		<div class="lines">
			<div class="line" v-for="x in 5" :key="x" :style="'grid-column:' + x + '/' + x"></div>

			<NavigationLeft @rootNav="rootNav" @rootCategory="rootCategory" style="grid-column: 1/6; grid-row: 1" class="menu"></NavigationLeft>
			<AuthPopIn :isPopInVisible="isPopInVisible" @hidePopIn="isPopInVisible = false" :fileNumber="fileNumber"></AuthPopIn>

			<div v-if="!isMobile || (isNavOpen == false && isUlOpen == false)" class="global-container">
				<div class="title-container" style="grid-column: 2/6; grid-row: 2">
					<h1>{{ selectedCategory?.title }}</h1>
					<span class="date">
						updated
						{{ new Date(Date.parse(selectedCategory?.updated_at)).toLocaleDateString() }}</span
					>
				</div>
				<span style="grid-column: 2/6; grid-row: 3" v-html="selectedCategory?.description" class="text" v-if="selectedCategory?.description"></span>
				<div class="link-wrapper" style="grid-column: 2/6; grid-row: 4">
					<MButton
						v-if="selectedCategory?.file"
						dark
						:href="selectedCategory?.file"
						:text="selectedCategory['download_button'] || `download ${selectedCategory?.title}`"
						download
						:class="{
							marged: true,
							privatedl: selectedCategory?.private === 1,
						}"
						@click="handleDownload($event, selectedCategory?.private)"
					></MButton>
					<MCopyLink :url="`/fundamental-guidelines/${selectedBrand?.slug}/${selectedCategory?.slug}`"></MCopyLink>
				</div>

				<template v-for="(content, contentKey) in selectedCategory?.contents?.filter((c) => c.status > 1)" :key="`content-${content.id}`">
					<div :style="'grid-column: 1/6; grid-row: 5' + contentKey" class="elements">
						<h2 :id="`link-${content.slug}`" class="anchors">
							{{ content.title }}
							<span :id="content.slug" class="anchor"></span>
						</h2>
						<span v-html="content.description" class="text" v-if="content?.description"></span>

						<div class="link-wrapper">
							<MButton v-if="content.file" dark :href="content.file" :text="content['download_button'] || `download ${content.title}`" download :class="{ marged: true, privatedl: content.private === 1 }" @click="handleDownload($event, content.private)"></MButton>
							<MCopyLink :url="`/fundamental-guidelines/${selectedBrand?.slug}/${selectedCategory?.slug}#${content.slug}`"></MCopyLink>
						</div>

						<template v-for="component in content?.components?.filter((c) => c.status > 1)" :key="`component-${component.id}`">
							<template v-if="componentIds.includes(component.type)">
								<h3 v-if="component.title">{{ component.title }}</h3>
								<span v-html="component.description" v-if="component?.description"></span>
								<div class="container-text" v-if="component.config">
									<template v-for="value in 3" :key="value">
										<div class="tab-text" v-if="component.config['subtitle' + value] || component.config['text' + value]">
											<h3 v-if="component.config['subtitle' + value]">
												{{ component.config["subtitle" + value] }}
											</h3>
											<div v-if="component.config['text' + value]" v-html="component.config['text' + value]"></div>
										</div>
									</template>
								</div>
								<component :is="componentMap.filter((c) => c.type === component.type)[0]?.component" :ctype="component.type" :media="component?.config?.media" :config="component.config" :id="component.id" class="component"></component>
							</template>
						</template>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import GradientColor from "@/components/organisms/GradientColor.vue";
import IntervertColor from "@/components/organisms/IntervertColor.vue";
import MediaReader from "@/components/organisms/MediaReader.vue";
import MediaSlider from "@/components/organisms/MediaSlider.vue";
import ColorHover from "@/components/organisms/ColorHover.vue";
import TypoVariation from "@/components/organisms/TypoVariation.vue";
import MoveWeight from "@/components/organisms/MoveWeight.vue";
import TabComponent from "@/components/organisms/TabComponent.vue";
import AuthPopIn from "@/components/organisms/AuthPopIn";
import WriteSomething from "@/components/organisms/WriteSomething";
import ImageColumn from "@/components/organisms/ImageColumn";
import NavigationLeft from "@/components/organisms/NavigationLeft";
import { ref } from "@vue/reactivity";
import { computed, inject, nextTick, onUpdated, onMounted } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import MButton from "@/components/molecules/MButton.vue";
import MCopyLink from "@/components/molecules/MCopyLink";

export default {
	name: "TComponents",
	components: {
		NavigationLeft,
		MButton,
		MCopyLink,
		GradientColor,
		IntervertColor,
		MediaReader,
		MediaSlider,
		ColorHover,
		TypoVariation,
		MoveWeight,
		TabComponent,
		AuthPopIn,

		WriteSomething,
		ImageColumn,
	},
	setup() {
		const { selectedBrand, selectedCategory } = inject("navigation");
		const componentMap = ref([
			{ component: "", type: 1 },
			{ component: "intervert-color", type: 2 },
			{ component: "media-reader", type: 3 },
			{ component: "gradient-color", type: 4 },
			{ component: "tab-component", type: 5 },
			{ component: "image-column", type: 6 },
			{ component: "media-slider", type: 7 },
			{ component: "typo-variation", type: 8 },
			{ component: "move-weight", type: 9 },
			{ component: "color-hover", type: 10 },
			{ component: "write-something", type: 11 },
			{ component: "media-slider", type: 12 },
		]);

		const route = useRoute();
		const mobileMode = ref(null);
		onMounted(() => {
			mobileMode.value = Boolean(route.query.mobile);
			mobileMode.value == true ? (isUlOpen.value = false) : (isUlOpen.value = true);
			mobileMode.value == true ? (isNavOpen.value = false) : (isNavOpen.value = true);
		});

		onUpdated(() => {
			nextTick(() => {
				if (route.hash) {
					const el = document.querySelector(route.hash);
					if (el) el.scrollIntoView();
				}
			});
		});

		const componentIds = computed(() => componentMap.value.map((el) => el.type));

		const isNavOpen = ref(true);
		function toggle() {
			isNavOpen.value = !isNavOpen.value;
			isUlOpen.value = !isUlOpen.value;
		}
		const isUlOpen = ref(false);
		function toggleUl() {
			isUlOpen.value = !isUlOpen.value;
		}

		const isPopInVisible = ref(false);
		const fileNumber = ref(0);

		function handleDownload(e, isPrivate) {
			if (isPrivate) {
				e.preventDefault();
				fileNumber.value = e.target.getAttribute("href");
				isPopInVisible.value = true;
			}
		}

		const sections = document.getElementsByClassName("anchors");

		const body = document.getElementsByTagName("body")[0];
		body.addEventListener("scroll", navHighlighter);
		function navHighlighter() {
			let scrollY = body.scrollTop;
			sections.forEach((current) => {
				//const sectionHeight = current.offsetHeight;

				const sectionTop = current.offsetTop - 150;
				const sectionId = current.getAttribute("id");
				if (scrollY > sectionTop && sectionId != null) {
					const navigation = document.querySelectorAll(".navigation");
					navigation.forEach((elem) => {
						if (elem.getAttribute("id") === sectionId) {
							elem.classList.remove("selected");
							elem.classList.add("active");
						} else {
							elem.classList.remove("active");
						}
					});
				}
			});
		}

		return {
			isNavOpen,
			toggle,
			isUlOpen,
			toggleUl,
			componentIds,
			componentMap,
			isPopInVisible,
			fileNumber,
			handleDownload,
			selectedBrand,
			selectedCategory,
		};
	},

	computed: {
		isMobile() {
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				return true;
			} else {
				return false;
			}
		},
	},
	methods: {
		rootNav(val) {
			this.isNavOpen = val;
			if (val === false) this.isUlOpen = val;
		},
		rootCategory(value) {
			this.isUlOpen = value;
			if (value === false) this.isNavOpen = value;
		},
	},
};
</script>

<style lang="scss" scoped>
#components {
	display: flex;
	flex-direction: column;
	padding: 0 130px;
	padding-right: 110px;
	padding-top: 100px;
	min-height: 100vh;
	width: 100%;
	background-color: var(--lighter);
	word-break: normal;
	box-sizing: border-box;
	//overflow: hidden;
	@media screen and (max-width: 1200px) {
		padding: 64px 0;
		padding-top: 60px;
		padding-bottom: 0;
	}

	.lines {
		display: grid;
		grid-template-columns: repeat(5, 17.5fr);
		margin: 0;
		max-width: 100%;
		position: relative;
		@media screen and (max-width: 1200px) {
			margin-left: 20px;
			margin-right: 25px;
			grid-template-columns: repeat(2, 50fr);
		}

		.line {
			height: 100%;
			pointer-events: none;
			user-select: none;
			border-right: 1px solid var(--grey-line);
			grid-row: 1/1500;
			&:nth-child(1) {
				border-left: 1px solid var(--grey-line);
			}
			&:not(:nth-child(1n + 6)):not(:nth-child(1)):not(:nth-child(2)) {
				@media screen and (max-width: 1200px) {
					display: none;
				}
			}
		}
	}
}

.menu {
	//margin-top: -100px;
	margin-left: -130px;
	border-right: 1px solid;
	max-height: calc(100vh - 100px);

	&.-scroll {
		height: calc(100% - 197px);
		margin-bottom: 180px;
	}

	@media screen and (max-width: 1200px) {
		margin: 0;
		height: auto;
		grid-column: 1/6 !important;
	}
}
.title-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media screen and (max-width: 1200px) {
		flex-direction: column;
		align-items: flex-start;
		margin: 0;
		grid-column: 1/6 !important;
	}
}
.date {
	margin-top: 70px;
	@media screen and (max-width: 1200px) {
		margin-bottom: 20px;
		margin-top: initial;
		padding-left: 0;
	}
}

h1 {
	font-size: 100px;
	font-weight: 700;
	margin-right: 0;
	position: relative;
	margin-block-start: 0;
	margin-block-end: 0;
	margin-top: 70px;
	margin-bottom: 30px;
	flex: 1;
	.anchor {
		z-index: -10000;
		position: absolute;
		top: -90px;
	}
	@media screen and (max-width: 1200px) {
		font-size: 45px;
		margin-bottom: 0;
		.anchor {
			z-index: -10000;
			position: absolute;
			top: -60px;
		}
	}
}
h2 {
	font-size: 36px;
	font-weight: 700;
	margin: 0 32px;
	flex-grow: 1;
	position: relative;
	margin-block-start: 0;
	margin-block-end: 0;
	margin-top: 70px;
	margin-bottom: 20px;
	.anchor {
		z-index: -10000;
		position: absolute;
		top: -90px;
	}
	@media screen and (max-width: 1200px) {
		font-size: 24px;
		.anchor {
			z-index: -10000;
			position: absolute;
			top: -60px;
		}
	}
}

.container-text {
	display: flex;
	padding: 0 32px;
	@media screen and (max-width: 1200px) {
		flex-direction: column;
	}
	.tab-text {
		display: flex;
		flex-direction: column;
		flex: 1;
		margin-top: 30px;
		&:nth-child(1),
		&:nth-child(2) {
			margin-right: 20px;
		}
		h3 {
			margin: 0;
			padding-top: 0;
			flex-grow: 0;
		}
		span {
			padding: 0;
		}
	}
}
h3 {
	font-size: 24px;
	font-weight: 700;
	margin: 0 32px;
	flex-grow: 1;
	position: relative;
	margin-block-start: 0;
	margin-block-end: 0;
	margin-top: 60px;
	.anchor {
		z-index: -10000;
		position: absolute;
		top: -90px;
	}
}
.text {
	padding: 0;
	@media screen and (max-width: 1200px) {
		grid-column: 1/6 !important;
	}
}
span {
	padding: 0 32px;
	padding-bottom: 0;
}

.link-wrapper {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 40px;
	.emphaziz {
		margin-left: 0;
		.download-img {
			background-image: url("../../assets/icon-lock-white.svg") !important;
		}
	}
	.copy-link {
		padding-left: 0;
	}
	@media screen and (max-width: 1200px) {
		grid-column: 1/6 !important;
	}
}

.elements {
	margin-left: 204px;
	margin-right: -110px;
	@media screen and (max-width: 1200px) {
		margin-left: -20px;
		margin-right: -25px;
		grid-column: 1/6 !important;
	}
	span {
		padding: 0;
	}
	h2,
	.text,
	.link-wrapper,
	h3,
	span {
		padding-left: calc(20% - 184px);
		@media screen and (max-width: 1200px) {
			padding-left: 20px;
			padding-right: 25px;
		}
	}
	h2 {
		margin: 0;

		padding-top: 70px;
		margin-bottom: 20px;
	}
	h3 {
		margin: 0;
		padding-top: 60px;
	}
}

.elements {
	&:last-child {
		margin-bottom: 180px;
	}
}

.global-container {
	grid-area: 1 / 1 / auto / 6;
	display: grid;
	grid-template-columns: repeat(5, 17.5fr);
	@media screen and (max-width: 1200px) {
		grid-area: 2 / 1 / auto / 6;
	}
}
</style>