<template>
  <div id="gradient-slider" @click="setGradientXPosition">
    <div
      v-for="gradient, key in gradients"
      :key="key"
      :class="{ line: true, current: gradient.current }"
      @click="handleChangeGradient(key)">
    </div>
    <div class="colorname">{{ gradients[currentIndex].name }}</div>
  </div>
  <div class="gradient" :style="styleObject">
    <img src="../../assets/logo-renault-white.svg" alt="renault logo">
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { computed } from '@vue/runtime-core';
export default {
  name: "MGradientSlider",
  setup() {
    const gradientXPosition = ref(0);
    const gradients = ref([
      { name: "dark blue", colors: ["#01053C", "#09108E"], current: true },
      { name: "light blue", colors: ["#02307E", "#0365C5"], current: false },
      { name: "turquoise", colors: ["#196B7D", "#196B7D"], current: false },
      { name: "green purple", colors: ["#479E59", "#479E59"], current: false },
      { name: "red", colors: ["#4D1105", "#DE2A40"], current: false },
      { name: "orange", colors: ["#751306", "#D26D26"], current: false },
    ])

    const styleObject = computed(() => ({
      'background-image': `linear-gradient(135deg, #01053C, #09108E)`
    }))

    const currentIndex = computed(() => {
      let result;
      gradients.value.forEach((gradient, index) => {
        if (gradient.current) {
          result = index
        }
      })
      return result || 0
    })

    function setGradientXPosition(e) {
      const rect = e.target.getBoundingClientRect();
      gradientXPosition.value = e.clientX - rect.left;
    }

    function handleChangeGradient (index) {
      gradients.value[currentIndex.value].current = false
      gradients.value[index].current = true
      const colors = gradients.value[index].colors
      styleObject.value['background-image'] = `linear-gradient(135deg, ${colors[0]}, ${colors[1]})`
    }

    return { gradientXPosition, setGradientXPosition, gradients, handleChangeGradient, styleObject, currentIndex };
  },
};
</script>

<style lang="scss" scoped>
#gradient-slider {
  margin: 32px 0;
  display: flex;
  align-items: flex-start;
  margin: 32px;
  margin-bottom: 64px;
  position: relative;
  @media screen and (max-width: 1200px) {
    margin-left: 32px;
  }
  .colorname{
    position: absolute;
    bottom: -24px;
    font-size: 16px;
    font-weight: 700;
  }
  .line {
    height: 80px;
    width: 16px;
    position: relative;
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    &::after {
      content: "";
      position: absolute;
      background-color: var(--darker);
      height: 32px;
      width: 1px;
      left: 6px;
      bottom: 0;
      transition: all 0.1s ease-in-out;
    }
    &:hover {
      &::after {
        height: 64px;
      }
    }
    &.current {
      border: 8px solid var(--darker);
      width: 0;
      height: 0px;
      border-left: 9px solid transparent;
      border-bottom: 16px solid transparent;
      border-right: 9px solid transparent;
      &::after {
        height: 64px;
        left: -1px;
        top: 8px;
      }
    }
  }
}

.gradient {
  background-color: var(--darker);
  flex-grow: 1;
  margin: 32px;
  box-sizing: border-box;
  @include media-container;
  position: relative;
  padding-bottom: 56.25%;
  align-self: center;
  img {
    position: absolute;
    top: 25%;
    width: 50%;
    height: 50%;
    padding: 0;
    @media screen and (max-width: 1200px) {
      height: initial;
      width: 30%;
      top: 25%;
    }
  }
}
</style>
