<template>
  <section class="landing">
    <div class="dark-block">
      <DarkMode />
      <ButtonGridMode />
    </div>

    <div
      class="lines"
      v-if="brandPlatform && fundamentalGuidelines && firstWave"
    >
      <div
        class="line"
        v-for="x in 5"
        :key="x"
        :style="'grid-column:' + x + '/' + x"
      ></div>
      <h1
        class="title _1"
        style="grid-column: 1/6; grid-row: 1"
        v-html="firstWave.title"
        id="h1"
      ></h1>

      <div class="videos" style="grid-column: 2/6; grid-row: 2">
        <template v-if="checkURL(medias.first_wave) === true">
          <div class="illust __1">
            <img :src="medias.first_wave" />
          </div>
        </template>

        <template v-if="checkURL(medias.first_wave) === false">
          <video
            :id="`video-player-${1}`"
            :poster="medias.first_wave_cover"
            class="video"
          >
            <source type="video/mp4" :src="medias.first_wave" />
          </video>
          <span
            @click="toggle1(1)"
            class="controle"
            :class="{ hidden: hidden, play: !playing1, pause: playing1 }"
          ></span>
        </template>
      </div>

      <p class="title _2" style="grid-column: 1/6; grid-row: 3">
        <span> {{ brandPlatform.title_external_link }} </span>
        <external-link :href="brandPlatform.external_link"></external-link>
      </p>

      <p style="grid-column: 1/6; grid-row: 4">
        <span class="subtitle">{{ brandPlatform.title_main_paragraph }} </span>
        {{ brandPlatform.main_paragraph }}
        <external-link
          :href="brandPlatform.external_link_main_paragraph"
        ></external-link>
      </p>
      <div class="brand __column">
        <p style="grid-column: 1" class="array1">
          <span class="subtitle">{{
            brandPlatform.title_triptych_paragraph1
          }}</span
          >{{ brandPlatform.triptych_paragraph1 }}
          <external-link
            :href="brandPlatform.external_link_triptych_paragraph1"
          ></external-link>
        </p>
        <p style="grid-column: 3" class="array2">
          <span class="subtitle">{{
            brandPlatform.title_triptych_paragraph2
          }}</span
          >{{ brandPlatform.triptych_paragraph2 }}
          <external-link
            :href="brandPlatform.external_link_triptych_paragraph2"
          ></external-link>
        </p>
        <p style="grid-column: 5" class="array3">
          <span class="subtitle">{{
            brandPlatform.title_triptych_paragraph3
          }}</span>
          {{ brandPlatform.triptych_paragraph3 }}
          <external-link
            :href="brandPlatform.external_link_triptych_paragraph3"
          ></external-link>
        </p>
      </div>

      <p class="title _3" style="grid-column: 1/6; grid-row: 9">
        <span> {{ fundamentalGuidelines.title_external_link }} </span>
        <external-link
          :href="fundamentalGuidelines.external_link"
        ></external-link>
      </p>

      <div class="fundamental __title">
        <div class="videos __2" style="grid-column: 1/4; grid-row: 10">
          <template v-if="checkURL(medias.visual_1) === true">
            <div class="illust __2">
              <img :src="medias.visual_1" />
            </div>
          </template>

          <template v-if="checkURL(medias.visual_1) === false">
            <video
              :id="`video-player-${2}`"
              :poster="medias.visual_1_cover"
              class="video"
            >
              <source type="video/mp4" :src="medias.visual_1" />
            </video>
            <span
              @click="toggle2(2)"
              class="controle"
              :class="{ hidden: hidden, play: !playing2, pause: playing2 }"
            ></span>
          </template>
        </div>
        <p style="grid-column: 5; grid-row: 10">
          <span class="subtitle">{{
            fundamentalGuidelines.title_paragraph_1
          }}</span>
          {{ fundamentalGuidelines.paragraph_1 }}
          <external-link
            :href="fundamentalGuidelines.external_link_paragraph_1"
          ></external-link>
        </p>
      </div>
      <div style="" class="fundamental __text">
        <p style="grid-column: 2; grid-row: 11">
          <span class="subtitle">{{
            fundamentalGuidelines.title_paragraph_2
          }}</span>
          {{ fundamentalGuidelines.paragraph_2 }}
          <external-link
            :href="fundamentalGuidelines.external_link_paragraph_2"
            style="grid-column: 2; grid-row: 11"
          ></external-link>
        </p>
      </div>

      <div class="videos __last" style="grid-column: 2/6; grid-row: 12">
        <template v-if="checkURL(medias.visual_2) === true">
          <div class="illust __3">
            <img :src="medias.visual_2" />
          </div>
        </template>

        <template v-if="checkURL(medias.visual_2) === false">
          <video
            :id="`video-player-${3}`"
            :poster="medias.visual_2_cover"
            class="video"
          >
            <source type="video/mp4" :src="medias.visual_2" />
          </video>
          <span
            @click="toggle3(3)"
            class="controle"
            :class="{ hidden: hidden, play: !playing3, pause: playing3 }"
          ></span>
        </template>
      </div>

      <p style="grid-column: 4/6; grid-row: 13" class="other-sections">
        {{ fundamentalGuidelines.paragraph }}
      </p>
      <div class="internal_links" style="grid-column: 4/6; grid-row: 14">
        <div
          v-for="(item, index) in fundamentalGuidelines.internal_links"
          :key="index"
        >
          <a class="internal_link" :href="item.link">{{ item.text }}</a>
        </div>
      </div>

      <p class="title" style="grid-column: 1/6; grid-row: 15">
        <span v-html="downloads.title_external_link"> </span>
        <external-link :href="downloads.external_link"></external-link>
      </p>

      <most-download></most-download>
    </div>
  </section>
</template>
<!--
<h1 id="h1">la nouvelle vague </h1>
    <div v-html="content" class="content" id="paragraphsContainer"></div>
-->
<script>
import { ref } from "@vue/runtime-core";
import axios from "axios";
import ExternalLink from "@/components/organisms/ExternalLink.vue";
import MostDownload from "@/components/molecules/MostDownload.vue";
import DarkMode from "@/components/organisms/DarkMode.vue";
import ButtonGridMode from "@/components/organisms/ButtonGridMode.vue";
//import { onUpdated } from "@vue/runtime-core";
import isMobile from "is-mobile";

export default {
  name: "Landing",
  components: {
    ExternalLink,
    MostDownload,
    DarkMode,
    ButtonGridMode,
  },

  setup() {
    //const h1 = ref();
    const firstWave = ref();
    const brandPlatform = ref();
    const fundamentalGuidelines = ref();
    const downloads = ref();
    const medias = ref();
    const isMobileDevice = isMobile();

    async function loadContent() {
      const result = await axios.get(
        `${process.env.VUE_APP_API}/page-contents/home`,
        {
          headers: {
            Authorization: "Bearer B5p3PKmh86DJ79Y0SCa88OEpiUeYJZKcP4Ajt6vdKH9",
          },
        }
      );

      firstWave.value = result?.data?.data?.config?.first_wave;
      brandPlatform.value = result?.data?.data?.config?.brand_platform;
      fundamentalGuidelines.value =
        result?.data?.data?.config?.fundamental_guidelines;
      downloads.value = result?.data?.data?.config?.most_requested_downloads;
      medias.value = result?.data?.data?.config?.media;
    }
    loadContent();

    function checkURL(url) {
      return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
    }

    const playing1 = ref(false);

    function toggle1(id) {
      const video = document.getElementById(`video-player-${id}`);
      console.log(video);
      if (playing1.value) video.pause();
      else video.play();
      playing1.value = !playing1.value;
    }

    const playing2 = ref(false);
    function toggle2(id) {
      const video = document.getElementById(`video-player-${id}`);
      if (playing2.value) video.pause();
      else video.play();
      playing2.value = !playing2.value;
    }

    const playing3 = ref(false);
    function toggle3(id) {
      const video = document.getElementById(`video-player-${id}`);
      if (playing3.value) video.pause();
      else video.play();
      playing3.value = !playing3.value;
    }

    return {
      firstWave,
      brandPlatform,
      fundamentalGuidelines,
      downloads,
      medias,
      isMobileDevice,
      checkURL,
      playing1,
      toggle1,
      playing2,
      toggle2,
      playing3,
      toggle3,
    };
  },
};
</script>
<style lang="scss" scoped>
body.dark-mode {
  .play {
    &::after {
      filter: invert(0);
    }
  }
}
.landing {
  display: flex;
  flex-direction: column;
  padding: 0 130px;
  padding-right: 110px;
  padding-top: 100px;
  min-height: 100vh;
  width: 100%;
  background-color: var(--lighter);
  word-break: normal;
  box-sizing: border-box;
  @media screen and (max-width: 1200px) {
    padding: 64px 0;
    padding-top: 60px;
    padding-bottom: 0;
  }
  .dark-block {
    position: fixed;
    top: 401px;
    margin-left: -90px;
    z-index: 5;
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }

  .title {
    font-size: 6vw;
    line-height: 97px;
    font-weight: 700;
    margin-block-start: 0;
    margin-block-end: 0;
    padding: 0;
    display: flex;
    align-items: baseline;
    margin-left: -4px;
    @media screen and (max-width: 1200px) {
      margin-left: 0;
    }

    span {
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      margin-right: 20px;
      @media screen and (max-width: 1200px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &._1 {
      margin-top: 143px;
      margin-bottom: 70px;
      flex-direction: column;
      align-items: flex-start;
      z-index: 2;
      @media screen and (max-width: 1200px) {
        margin-top: 70px;
      }
    }
    &._2 {
      margin-bottom: 50px;
      margin-top: 100px;
    }
    &._3 {
      margin-top: 100px;
    }
    @media screen and (max-width: 1200px) {
      grid-column: 1/6 !important;
      font-size: 45px;
      line-height: 48px;
      padding: 0;
      margin-top: 100px;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .array1 {
    grid-row: 6;
  }
  .array2 {
    grid-row: 6;
    @media screen and (max-width: 1200px) {
      grid-row: 7;
    }
  }
  .array3 {
    grid-row: 6;
    @media screen and (max-width: 1200px) {
      grid-row: 8;
    }
  }
  .subtitle {
    font-size: 36px;
    line-height: 33px;
    font-weight: 700;
    padding: 0;
    margin-bottom: 20px;
    @media screen and (max-width: 1200px) {
      grid-column: 1/6 !important;
      font-size: 24px;
      line-height: 55px;
    }
  }
  p {
    font-size: 21px;
    line-height: 31px;
    font-weight: normal;
    align-self: self-end;
    margin-block-start: 0;
    margin-block-end: 0;
    @media screen and (max-width: 1200px) {
      font-size: 16px;
      line-height: 26px;
      grid-column: 1/6 !important;
    }
  }

  img {
    height: auto;
    width: 100%;
  }

  .lines {
    display: grid;
    grid-template-columns: repeat(5, 17.5fr);
    margin: 0;
    max-width: 100%;
    @media screen and (max-width: 1200px) {
      margin-left: 20px;
      margin-right: 25px;
      grid-template-columns: repeat(2, 50fr);
    }

    .line {
      height: 100%;
      pointer-events: none;
      -webkit-user-select: none;
      user-select: none;
      border-right: 1px solid var(--grey-line);
      grid-row: 1/30;
      &:nth-child(1) {
        border-left: 1px solid var(--grey-line);
      }
      &:nth-child(3) {
        @media screen and (max-width: 1200px) {
          display: none;
        }
      }

      &:nth-child(4) {
        @media screen and (max-width: 1200px) {
          display: none;
        }
      }
      &:nth-child(5) {
        @media screen and (max-width: 1200px) {
          display: none;
        }
      }
    }
  }
}

.fundamental {
  grid-row: 10;
  grid-column: 1/6;

  &.__title {
    display: grid;
    grid-template-columns: repeat(5, 17.5fr);
    margin-top: 70px;
    @media screen and (max-width: 1200px) {
      display: block;
    }
    p,
    img {
      display: grid;
      @media screen and (max-width: 1200px) {
        grid-column: 1/6 !important;
        display: block;
      }
    }
    p {
      align-self: center;
    }
  }
  &.__text {
    grid-row: 11;
    display: grid;
    grid-template-columns: repeat(5, 17.5fr);
    margin-bottom: 50px;
    margin-top: 50px;
    @media screen and (max-width: 1200px) {
      margin-bottom: 70px;
      grid-template-columns: repeat(2, 50fr);
    }
    p {
      @media screen and (max-width: 1200px) {
        margin-top: 0;
        grid-row: 12 !important;
        margin-bottom: 0;
      }
    }
  }
}
.brand {
  grid-row: 5;
  grid-column: 1/6;
  &.__column {
    display: grid;
    grid-template-columns: repeat(5, 17.5fr);
    height: auto;
    margin-top: 50px;

    p {
      align-self: flex-start;
      @media screen and (max-width: 1200px) {
        margin-bottom: 50px;
      }
      &:nth-child(3) {
        @media screen and (max-width: 1200px) {
          margin-bottom: 0;
        }
      }
    }
  }
}

.illust {
  position: relative;

  @media screen and (max-width: 1200px) {
    overflow: initial;
    margin: 40px 0;
    margin-bottom: 50px;
    grid-column: 1/6 !important;
  }
  &.__1 {
    //animation: transformtest 2s;
    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: (757 / 1346) * 100%;

      @media screen and (max-width: 1200px) {
        padding-top: (593 / 330) * 100%;
      }
    }
  }
  &.__2 {
    @media screen and (max-width: 1200px) {
      margin-top: 0;
    }
    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: (567 / 1009) * 100%;

      @media screen and (max-width: 1200px) {
        padding-top: (593 / 330) * 100%;
      }
    }
  }
  &.__3 {
    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: (371 / 673) * 100%;

      @media screen and (max-width: 1200px) {
        padding-top: (593 / 330) * 100%;
      }
    }
  }

  img {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    width: -webkit-fill-available;
    z-index: 1;
  }
}

.internal_links {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  @media screen and (max-width: 1200px) {
    margin-bottom: 0;
    grid-column: 1/6 !important;
  }
}

.internal_link {
  white-space: pre-line;
  font-size: 36px;
  line-height: 40px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  width: max-content;
  @media screen and (max-width: 1200px) {
    font-size: 24px;
    line-height: 34px;
  }
  &::after {
    content: "";
    width: 0%;
    height: 2px;
    background-color: var(--darker);
    transition: all 1s ease-in-out;
  }
  &:hover {
    &::after {
      width: 100%;
    }
  }
}

.downloads-link {
  margin-left: -20px;
  margin-bottom: 10px;
  @media screen and (max-width: 1200px) {
    margin-left: 0;
    margin-bottom: 0;
  }
}

.other-sections {
  padding-bottom: 30px;
}

.controle {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 60px;
  background-color: white;
  margin: 0;
  border-radius: 0;
  cursor: pointer;
  &::before {
    position: absolute;
    content: "";
    display: inline-block;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    //transition: all 0.3s cubic-bezier(0.87, 0, 0.13, 1);
  }
  &.play {
    &::before {
      height: 0;
      width: 0;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 14px solid #000;
    }
    &::after {
      content: "watch";
      color: var(--darker);
      filter: invert(1);
      background-color: transparent;
      position: absolute;
      right: 0;
      top: 45%;
      padding-left: 16px;
      font-size: 21px;
      font-weight: 700;
      transform: translate(100%, -50%);
    }
  }
  &.pause {
    &::before {
      width: 4px;
      height: 16px;
      // border: 0px solid var(--darker);
      border-left: 4px solid #000;
      border-right: 4px solid #000;
      border-top: 0px solid transparent;
      border-bottom: 0px solid transparent;
    }
    &::after {
      content: "pause";
      color: var(--darker);
      filter: invert(1);
      background-color: transparent;
      position: absolute;
      right: 0;
      top: 45%;
      padding-left: 16px;
      font-size: 21px;
      font-weight: 700;
      transform: translate(100%, -50%);
    }
  }
  &.hidden {
    display: none;
  }
}
.video {
  object-fit: cover;
  max-width: 100%;
  position: relative;
  @media screen and (max-width: 1200px) {
    height: 593px;
  }
}

.videos {
  position: relative;
  height: max-content;
  @media screen and (max-width: 1200px) {
    grid-column: 1/6 !important;
  }
  &.__2 {
    @media screen and (max-width: 1200px) {
      margin-bottom: 70px;
    }
  }
  &.__last {
    margin-bottom: 70px;
  }
}
</style>
