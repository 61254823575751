<template>
	<div class="notification-page">
		<div class="notification-container" v-if="filterProducts || content">
			<navigation-notifications @brandSelected="brandSelected" v-click-outside="nameOfCustomEventToCall" @brandList="showToggle" :show="toogleOpen"></navigation-notifications>
			<template v-for="(notif, index) in filterProducts" :key="index">
				<div class="notification">
					<div class="mobile">
						<div class="date">
							<span>{{ notif.date }}</span>
							<span class="tag" :class="{ show: notif.tag !== '' }">{{ notif.tag }}</span>
						</div>
						<div class="brand">{{ notif.brand }}</div>
						<div class="content">
							{{ notif.content }}
							<span v-if="notif.tag == 'updated!' || notif.tag == ''">has been updated in</span>
							<span v-if="notif.tag == 'new!'">has been added in</span>
							{{ notif.category }}
						</div>
					</div>
					<div class="mobile">
						<div class="link">
							<span>
								<router-link :to="{ path: `/fundamental-guidelines/${notif.brand.replace(/\s+/g, '-')}/${notif.category.replace('&', '').replace(/\s+/g, '-')}#${notif.content.replace('&', '').replace(/\s+/g, '-')}`, query: { mobile: true } }" class="">
									<img src="../../assets/icon-cta-arrow-black.svg" />
								</router-link>
							</span>
						</div>
					</div>
				</div>
			</template>
			<div class="pagination" v-if="content.length > 16 && getPageCount > 1">
				<span>{{ currentPage }}</span
				><span>/</span><span>{{ getPageCount }}</span>
				<img src="../../assets/arrow-filter-black.svg" class="previous" @click="clickCallback(currentPage - 1)" />
				<img src="../../assets/arrow-filter-black.svg" class="next" @click="clickCallback(currentPage + 1)" />
			</div>
			<div class="noresult" v-if="content.length == 0">
				<span>No result</span>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, computed } from "@vue/runtime-core";
import axios from "axios";
import NavigationNotifications from "@/components/molecules/NavigationNotifications";
export default {
	name: "Notification",
	components: {
		NavigationNotifications,
	},

	setup() {
		const content = ref();

		async function loadContent() {
			const result = await axios.get(`${process.env.VUE_APP_API}/downloads`, {
				headers: {
					Authorization: "Bearer B5p3PKmh86DJ79Y0SCa88OEpiUeYJZKcP4Ajt6vdKH9",
				},
			});
			content.value = Object.values(result?.data?.data);
		}

		// count number of page
		const parPage = ref(16);
		const currentPage = ref(1);
		const getPageCount = computed(() => {
			if (brandValue.value === null) {
				return Math.ceil(content.value.length / parPage.value);
			} else {
				return Math.ceil(filterProducts.value.length / parPage.value);
			}
		});

		loadContent();

		//change page table
		function clickCallback(pageNum) {
			if (pageNum === 0) return;
			if (pageNum <= getPageCount._value) {
				currentPage.value = Number(pageNum);
			}
		}

		const filterProducts = computed(() => {
			const current = currentPage.value * parPage.value;
			const start = current - parPage.value;
			if (brandValue.value === null) {
				return content.value?.slice(start, current);
			}
			if (brandValue.value !== null) {
				return content.value.filter((item) => item.brand === brandValue.value)?.slice(start, current);
			}
			if (brandValue.value !== null) {
				return content.value.filter((item) => item.brand === brandValue.value).slice(start, current);
			}
		});
		const brandValue = ref(null);
		function toggleBrand() {
			brandValue.value = !brandValue.value;
		}

		const toogleOpen = ref(false);
		function toggleShow() {
			toogleOpen.value = !toogleOpen.value;
		}

		function nameOfCustomEventToCall() {
			if (toogleOpen.value === true) {
				toogleOpen.value = false;
			}
		}

		return {
			content,
			toggleBrand,
			parPage,
			currentPage,
			getPageCount,
			clickCallback,
			brandValue,
			nameOfCustomEventToCall,
			toogleOpen,
			toggleShow,
			filterProducts,
		};
	},
	methods: {
		brandSelected(val) {
			this.brandValue = val;
			this.currentPage = 1;
		},

		showToggle(val) {
			this.toogleOpen = val;
		},
	},
};
</script>

<style lang="scss" scoped>
.notification-page {
	display: flex;
	padding-top: 100px;
	min-height: 100vh;
	padding-bottom: 70px;
	width: 100%;
	background-color: var(--lighter);
	box-sizing: border-box;
	@media screen and (max-width: 1200px) {
		padding-top: 61px;
		padding-bottom: 70px;
	}

	.notification-container {
		width: 100%;
	}

	.notification {
		display: flex;
		padding: 22.5px 130px;
		font-weight: 700;
		border-bottom: 1px solid var(--darker);
		@media screen and (max-width: 1200px) {
			flex-direction: row;
			align-items: center;
			padding: 20px;
		}
		&.isActive {
			font-weight: normal;
		}
		& > div {
			flex: 1;
			@media screen and (max-width: 1200px) {
				flex: 0;
			}
		}
		.mobile {
			&:first-child {
				flex: 5;
				display: flex;
				flex-direction: row;
				@media screen and (max-width: 1200px) {
					flex-direction: column;
				}
				.content {
					flex: 3;
					span {
						padding: 0;
						display: initial;
					}
				}
				& > div {
					flex: 1;
				}
			}
			@media screen and (max-width: 1200px) {
				flex: 1;
			}
		}
	}
}

.link {
	text-align: right;
	a {
		margin: 0;
	}
	span {
		padding: 0;
		align-items: flex-end;
	}
}

.pagination {
	padding: 0;
	padding-top: 40px;
	padding-left: 130px;
	display: flex;
	@media screen and (max-width: 1200px) {
		padding-left: 20px;
	}
	span {
		padding: 0;
		padding-right: 5px;
		font-weight: 700;
	}
	.previous {
		transform: rotate(-90deg);
		margin-right: 50px;
		margin-left: 35px;
		cursor: pointer;
	}
	.next {
		transform: rotate(90deg);
		cursor: pointer;
	}
}

.date {
	flex-direction: row;
	display: flex;
	span {
		padding: 0;
		&.tag {
			margin-left: 22px;
			font-weight: 700;
			font-size: 16px;
			padding: 5px;
			line-height: 1;
		}
		&.show {
			background: $yellow2;
		}
	}
}

.noresult {
	span {
		align-items: center;
	}
}

.snackbar-download {
	padding: 16px;
	line-height: 1;
}

body.dark-mode {
	.link {
		filter: invert(1);
	}
	.pagination {
		.next,
		.previous {
			filter: invert(1);
		}
	}
}
</style>