<template>
  <div class="container-learn">
    <a  class="link" @click="showModal">learn more</a>
    <div class="modal" :class="{ hide: !show }">
        <div class="title-modal-showcase"><span v-for="(test, key) in chunkString(title, 24)" :key="key" class="subtitle">{{test}}</span></div>
        <span @click="showModal" class="close"> <img src='../../assets/cta-close-black.svg'></span>
        <span class="description-modal-showcase" v-html="text"></span>
        <div class="credits-container">
          <span class="credits-modal-showcase">credits</span>
          <div v-for="(credit, index) in credits" :key="index">
              <span class="category-modal-showcase"> {{credit.category}} </span>
              <div class="collaborators-container">
                  <div class="collaborator" v-for="(collaborator, index) in credit.collaborators" :key="index">
                    <span class="name">{{collaborator.name}}</span>
                    <span class="job">{{collaborator.job}}</span>
                  </div>
              </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  name: "LearnMore",
   props: {
     credits: { type: [Array], required: false },
     text: {type: [String], required: false},
     title: {type: [String], required: false}
  },
  setup (props, { emit }) {

    const show = ref(false);

    function showModal() {
      show.value = !show.value
      emit("show-modal", show.value);
    }
    function chunkString(str, length) {
      return str.match(new RegExp('.{1,' + length + '}', 'g'));
    }

    return {showModal, show, chunkString}
  }
}
</script>

<style lang="scss" scoped>
.container-learn {
  min-width: 20%;
  padding-top: 10px;

}

body.dark-mode {
  .link::after {
    filter: invert(1);
  }
  .close{
    filter: invert(1);
  }
}
.link {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  padding: 0;
  margin: 0;
  line-height: initial;
  padding-left: 20px;
  @media screen and (max-width: 1200px) {
    padding-left: 0;
  }
  &::after {
    content: "";
    margin-left: 10px;
    background-image: url("../../assets/icon-cta-arrow-black.svg");
    background-size: 10px;
    background-position: center;
    overflow: hidden;
    width: 10px;
    height: 10px;
  }
  &:hover {

    &::after {
     animation: bounce 0.6s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    }
  }
}

.modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  background-color: var(--lighter);
  z-index: 64;
  height: 100vh;
  width: 100vw;
  padding: 76px 130px;
  box-sizing: border-box;
  overflow: auto;
  @media screen and (max-width: 1200px) {
    padding: 70px 20px;
   }
  &.hide {
    display: none;
  }
  .close {
    position: absolute;
    cursor: pointer;
    top: 60px;
    right: 130px;
    padding: 0;
    @media screen and (max-width: 1200px) {
      top: 30px;
      right: 20px;
    }
    @media screen and (max-width: 500px) {
      top: 32px;
      right: 32px;
      cursor: pointer;
    }
    img {
      width: 35px;
      height: 35px;
      @media screen and (max-width: 500px) {
        width: 20px;
        height: 20px;
      }
    }
  }
}





.title-modal-showcase {
    font-size: 100px;
    line-height: 97px;
    padding-bottom: 30px;
    font-weight: 700;
    @media screen and (max-width: 1200px) {
      font-size: 45px;
      line-height: 48px;
    }
    .subtitle {
      padding: 0;
    }
  }

.category-modal-showcase {
  padding-top:30px;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  @media screen and (max-width: 1200px) {
    font-size: 21px;
    padding-bottom:30px;
  }
}

.credits-modal-showcase {
  font-size: 36px;
  line-height: 34px;
  font-weight: 700;
  padding-top: 60px;
  @media screen and (max-width: 1200px) {
    font-size: 24px;
    padding-bottom: 50px;
  }
}

.collaborators-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  .collaborator {
    display: flex;
    flex-direction: column;
    flex: 1 0 16%;
    margin-right:130px;
    padding-top: 20px;
    font-size: 21px;
    line-height: 28px;
     @media screen and (max-width: 1200px) {
       width: 100%;
       font-size: 16px;
       line-height: 22px;
       padding-bottom: 30px;
       margin-right: 0;
     }
    &:nth-child(4n) {
      margin-right: 0;
    }

    .name, .job {
      padding: 0;
    }
    .name {
      font-weight: 700;
    }
  }
}
</style>
