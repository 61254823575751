<template>
  <section class="landing">
    <div class="dark-block">
      <DarkMode />
      <ButtonGridMode />
    </div>
    <div class="lines" v-if="makingWave && firstWave && forLife && download">
      <div
        class="line"
        v-for="x in 5"
        :key="x"
        :style="'grid-column:' + x + '/' + x"
      ></div>
      <AuthPopIn
        :isPopInVisible="isPopInVisible"
        @hidePopIn="isPopInVisible = false"
        :fileNumber="fileNumber"
      ></AuthPopIn>
      <div style="grid-column: 1/6; grid-row: 1" class="first-wave">
        <div class="wave-illust" style="grid-column: 1/6; grid-row: 1">
          <img :src="media.first_wave" />
        </div>
        <div style="grid-column: 1/6; grid-row: 1" class="text-column">
          <div>
            <h1 class="max-title" id="h1">{{ firstWave.text }}</h1>
            <span class="subtitle" v-html="firstWave.under_text"></span>
          </div>
          <div class="content">
            <span class="subtitle _2">{{ firstWave.on_title }}</span>
            <div class="return">
              <span class="title">{{ firstWave.title }}</span>
              <a id="bottomreturn" @click="goScroll"
                ><img src="../../assets/cta-bottom.svg" alt="cta top"
              /></a>
            </div>
          </div>
        </div>
      </div>

      <span
        id="firstparagraph"
        class="text firstparagraph"
        style="grid-column: 2/6; grid-row: 5"
      >
        {{ makingWave.main_paragraph1 }}</span
      >
      <span class="content" style="grid-column: 3/6; grid-row: 6">{{
        makingWave.paragraph1
      }}</span>

      <div class="illust" style="grid-column: 1/5; grid-row: 7">
        <img :src="media.making_wave" />
      </div>
      <span class="content" style="grid-column: 1/6; grid-row: 8">{{
        makingWave.paragraph2
      }}</span>
      <span
        class="text makingwave-paragraph"
        style="grid-column: 2/6; grid-row: 9"
        >{{ makingWave.main_paragraph2 }}</span
      >
      <slider></slider>

      <span class="text forlife" style="grid-column: 2/6; grid-row: 11">{{
        forLife.main_paragraph
      }}</span>
      <span class="content" style="grid-column: 3/6; grid-row: 12">{{
        forLife.paragraph
      }}</span>

      <span
        class="text forlife paragraph"
        style="grid-column: 2/6; grid-row: 13"
        >{{ forLife.paragraph2 }}</span
      >

      <div class="border-download" style="grid-column: 1/6; grid-row: 14"></div>

      <span
        class="text title -download"
        style="grid-column: 1/6; grid-row: 15"
        v-html="download.title"
      ></span>
      <template v-for="(down, index) in download" :key="index">
        <div
          v-if="index !== 'title' && down.brand !== null"
          class="download __column"
          :class="{ isActive: getCookie(privateDownload[index]) == true }"
          :style="'grid-row:' + 2 + index"
        >
          <div class="desktop">
            <span style="grid-column: 1/1;'" v-if="down.last_update !== null">
              {{ new Date(down.last_update).toLocaleDateString("fr-FR") }}
            </span>
            <span style="grid-column: 2/2">{{ down.brand }} </span>
            <span style="grid-column: 3/3">{{ down.content }}</span>
            <span style="grid-column: 4/4"></span>
            <template v-for="(link, key) in privateDownload" :key="key">
              <span
                :style="'grid-column: 5/5;grid-row:16+' + index"
                v-if="key == index && Number.isInteger(link) && link !== ''"
                ><a
                  @click="handleDownload($event, link)"
                  class="download-img _private"
                  :href="link"
                ></a
              ></span>
              <span
                :style="'grid-column: 5/5;grid-row:16+' + index"
                :href="link"
                v-if="key == index && !Number.isInteger(link) && link !== ''"
                ><a
                  class="download-img _public"
                  :href="link"
                  @click="downloadFile(link)"
                ></a
              ></span>
            </template>
          </div>
          <div class="mobile">
            <span style="grid-column: 1/1;'" v-if="down.last_update !== null">
              {{ new Date(down.last_update).toLocaleDateString("fr-FR") }}</span
            >
            <span style="grid-column: 2/2">{{ down.brand }}</span>
            <span style="grid-column: 3/3">{{ down.content }}</span>
            <span style="grid-column: 4/4"></span>
          </div>
          <div class="mobile">
            <template v-for="(link, key) in privateDownload" :key="key">
              <span
                :style="'grid-column: 5/5;grid-row:16+' + index"
                v-if="key == index && Number.isInteger(link) && link !== ''"
                ><a
                  @click="handleDownload($event, link)"
                  class="download-img _private"
                  :href="link"
                ></a
              ></span>
              <span
                :style="'grid-column: 5/5;grid-row:16+' + index"
                v-if="key == index && !Number.isInteger(link) && link !== ''"
                ><a
                  class="download-img _public"
                  :href="link"
                  @click="downloadFile(link)"
                ></a
              ></span>
            </template>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import { ref } from "@vue/reactivity";
import axios from "axios";
import Slider from "@/components/molecules/Slider.vue";
import AuthPopIn from "@/components/organisms/AuthPopIn";
import isMobile from "is-mobile";
import DarkMode from "@/components/organisms/DarkMode.vue";
import ButtonGridMode from "@/components/organisms/ButtonGridMode.vue";
import { onUpdated, nextTick } from "@vue/runtime-core";
export default {
  name: "BrandPlatform",
  components: {
    Slider,
    AuthPopIn,
    DarkMode,
    ButtonGridMode,
  },
  setup() {
    //const h1 = ref();
    const firstWave = ref();
    const makingWave = ref();
    const forLife = ref();
    const download = ref();
    const media = ref();
    const isMobileDevice = isMobile();
    const privateDownload = ref();
    const isPopInVisible = ref(false);
    const fileNumber = ref(0);

    async function loadContent() {
      const result = await axios.get(
        `${process.env.VUE_APP_API}/page-contents/brand-platform`,
        {
          headers: {
            Authorization: "Bearer B5p3PKmh86DJ79Y0SCa88OEpiUeYJZKcP4Ajt6vdKH9",
          },
        }
      );
      firstWave.value = result?.data?.data?.config?.first_wave;
      makingWave.value = result?.data?.data?.config?.making_wave;
      forLife.value = result?.data?.data?.config?.for_life;
      download.value = result?.data?.data?.config?.download;
      media.value = result?.data?.data?.config?.media;
      /* eslint-disable-next-line camelcase */
      privateDownload.value = result?.data?.data?.config?.private_media;
    }

    loadContent();

    onUpdated(() => {
      /*
      h1.value = document.getElementById("h1");
      if (!h1.value) return;
      const title = h1.value.innerHTML;
      const renderedtitle = title
        .split("")
        .map((char, indexchar) =>
          char === " "
            ? `<span></span>`
            : `
      <div
      class="char"
      style="
      animation-delay: ${indexchar / 30}s">${char}</div>`
        )
        .join("")
        .split(`<span></span>`)
        .map((word) => `<div class="word">${word}</div>`);
      h1.value.innerHTML = renderedtitle.join("");
      */

      nextTick(() => {
        const firstparagraph = document.getElementById("firstparagraph");
        firstparagraph.addEventListener("click", goScroll.bind(this), false);
      });
    });

    function goScroll() {
      const firstparagraph = document.getElementById("firstparagraph");
      const height = firstparagraph.offsetTop;
      const body = document.getElementsByTagName("body")[0];
      body.scrollTo(0, height - 100);
    }

    // Show popin
    function handleDownload(e, isPrivate) {
      if (isPrivate) {
        e.preventDefault();
        fileNumber.value = e.target.getAttribute("href");
        isPopInVisible.value = true;
      }
    }
    function getCookie(name) {
      // Split cookie string and get all individual name=value pairs in an array
      let cookieArr = document.cookie.split(";");
      // Loop through the array elements
      for (let i = 0; i < cookieArr.length; i++) {
        let cookiePair = cookieArr[i].split("=");
        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if (typeof name === "string") {
          const lastSegment = name.split("/").pop();
          if ("file_" + lastSegment == cookiePair[0].trim()) return true;
        }
        if ("file_" + name == cookiePair[0].trim()) {
          return true;
        }
      }
      // Return null if not found
      return null;
    }

    function downloadFile(link) {
      const lastSegment = link.split("/").pop();
      document.cookie = "file_" + lastSegment + "= true";
    }

    return {
      firstWave,
      makingWave,
      forLife,
      download,
      media,
      isMobileDevice,
      privateDownload,
      isPopInVisible,
      handleDownload,
      AuthPopIn,
      fileNumber,
      goScroll,
      getCookie,
      downloadFile,
    };
  },
};
</script>
<style lang="scss" scoped>
.landing {
  display: flex;
  flex-direction: column;
  padding: 0 130px;
  padding-right: 110px;
  padding-top: 100px;
  min-height: 100vh;
  width: 100%;
  background-color: var(--lighter);
  word-break: normal;
  box-sizing: border-box;
  font-weight: 700;
  @media screen and (max-width: 1200px) {
    padding: 64px 0;
    padding-top: 60px;
    padding-bottom: 0;
  }
  @media screen and (max-width: 1200px) {
    padding: 64px 0;
    padding-top: 60px;
    padding-bottom: 0;
  }
  .dark-block {
    position: fixed;
    top: 401px;
    margin-left: -90px;
    z-index: 5;
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
  span.text {
    display: flex;
    flex-wrap: wrap;
    font-size: 100px;
    line-height: 97px;
    padding: 0;
    margin-left: -4px;
    @media screen and (max-width: 1200px) {
      font-size: 45px;
      line-height: 48px;
      grid-column: 1/6 !important;
    }

    &.-download {
      margin-bottom: 50px;
      padding: 0;
    }
    &.makingwave-paragraph {
      margin: 20px 0;
    }
    &.firstparagraph,
    &.forlife {
      margin-bottom: 20px;
    }
  }

  .forlife {
    &.paragraph {
      margin-top: 100px;
    }
  }
  .content {
    width: 100%;
    padding: 0;
    font-size: 36px;
    line-height: 40px;
    overflow: hidden;
    @media screen and (max-width: 1200px) {
      font-size: 24px;
      line-height: 34px;
      grid-column: 1/6 !important;
    }
  }

  .first-wave {
    grid-column: 1/6;
    grid-row: 1;
    display: grid;
    grid-template-columns: repeat(5, 17.5fr);
    margin-bottom: 100px;
    @media screen and (max-width: 1200px) {
      height: 750px;
    }
    .wave-illust {
      position: relative;
      margin-left: -130px;
      margin-right: -110px;
      @media screen and (max-width: 1200px) {
        margin-left: -20px;
        margin-right: -25px;
        height: 812px;
      }
      &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: (952 / 1920) * 100%;

        @media screen and (max-width: 1200px) {
          padding-top: initial;
        }
      }
      img {
        object-fit: cover;
        position: absolute;
        top: 0px;
        left: 0;
        min-width: 100%;
        min-height: 100%;
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        @media screen and (max-width: 1200px) {
          top: -61px;
        }
      }
    }
  }
}

.text-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
  .max-title {
    margin-top: 130px;
    color: #fff;
    padding: 0;
    font-size: 100px;
    line-height: 97px;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    margin-block-end: 0;
    @media screen and (max-width: 1200px) {
      font-size: 45px;
      line-height: 40px;
      margin-top: 70px;
    }
  }
  .subtitle {
    color: #fff;
    font-size: 36px;
    line-height: 40px;
    padding: 0;
    //animation: transformtest 2s;
    &._2 {
      animation: none;
    }

    @media screen and (max-width: 1200px) {
      margin-top: 10px;
      font-size: 24px;
      line-height: 26px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 66px;
    @media screen and (max-width: 1200px) {
      padding-bottom: 61px;
    }
    .title {
      font-size: 100px;
      line-height: 110px;
      padding: 0;
      color: #fff;
      @media screen and (max-width: 1200px) {
        font-size: 45px;
        line-height: initial;
      }
    }
    .subtitle {
      font-size: 36px;
      line-height: 33px;
      padding: 0;
      color: #fff;
      @media screen and (max-width: 1200px) {
        font-size: 24px;
        line-height: initial;
      }
    }
  }
}
.lines {
  display: grid;
  grid-template-columns: repeat(5, 17.5fr);
  @media screen and (max-width: 1200px) {
    margin-left: 20px;
    margin-right: 25px;
    grid-template-columns: repeat(2, 50fr);
  }

  .line {
    border-right: 1px solid var(--grey-line);
    grid-row: 1/30;
    &:nth-child(1) {
      border-left: 1px solid var(--grey-line);
    }
    &:nth-child(3) {
      @media screen and (max-width: 1200px) {
        display: none;
      }
    }

    &:nth-child(4) {
      @media screen and (max-width: 1200px) {
        display: none;
      }
    }
    &:nth-child(5) {
      @media screen and (max-width: 1200px) {
        display: none;
      }
    }
  }
}

.container-slider {
  margin-top: 50px;
  margin-bottom: 100px;
}

.illust {
  position: relative;
  overflow: hidden;
  margin-bottom: 100px;
  margin-top: 50px;
  @media screen and (max-width: 1200px) {
    overflow: initial;
  }

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: (741 / 1346) * 100%;

    @media screen and (max-width: 1200px) {
      padding-top: (593 / 330) * 100%;
    }
  }
  img {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
  }
}

.download {
  grid-column: 1/6;
  border-top: 1px solid var(--darker);
  position: relative;
  &::before,
  &::after {
    content: "";
    display: block;
    border-top: 1px solid var(--darker);
    border-bottom: 1px solid var(--darker);
    margin-top: -1px;
    min-height: 100%;
    position: absolute;
  }
  &::before {
    margin-left: -130px;
    width: 130px;
  }
  &::after {
    width: 110px;
    right: 0;
    margin-right: -110px;
  }
  &.__column {
    display: grid;
    grid-template-columns: repeat(5, 17.5fr);
    height: auto;
    font-weight: 700;
    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(2, 50fr);
    }

    &:last-child {
      margin-bottom: 100px;
      border-bottom: 1px solid var(--darker);
    }

    &.isActive {
      font-weight: normal;
    }

    span {
      padding: 25px 0;
      font-size: 21px;
      line-height: 34px;
      @media screen and (max-width: 1200px) {
        padding: 0;
        grid-column: 1/1 !important;
        font-size: 16px;
        line-height: 23px;
      }
      &:nth-child(5) {
        align-items: flex-end;
        @media screen and (max-width: 1200px) {
          grid-column: 2/2 !important;
        }
      }
    }
    .download-img {
      //pointer-events: none;
      margin-left: 10px;
      &._public {
        background-image: url("../../assets/icon-download-white.svg");
      }
      &._private {
        background-image: url("../../assets/icon-lock-white.svg");
      }
      width: 24px;
      height: 24px;
      border-radius: 24px;
      background-size: 24px;
      background-position: center;
      filter: invert(1);
      box-sizing: border-box;
      border: 2px solid var(--lighter);
      @media screen and (max-width: 1200px) {
        margin-right: 0;
      }
    }
    &:hover {
      .download-img {
        //pointer-events: none;
        background-position-y: 22px;
        transition: all 0.8s ease-in-out;
      }
    }
    .mobile {
      display: none;
      @media screen and (max-width: 1200px) {
        display: block;
        padding: 20px 0;
        &:nth-child(2) {
          grid-column: 1;
        }
        &:nth-child(3) {
          align-self: center;
          margin: 0 auto;
          margin-right: 0;
          grid-column: 2;
        }
      }
    }
    .desktop {
      grid-template-columns: repeat(5, 17.5fr);
      grid-column: 1/6;
      display: grid;
      @media screen and (max-width: 1200px) {
        display: none;
      }
    }
  }
}

body.dark-mode {
  .download.__column .download-img {
    &._public {
      filter:invert(0);
      border: 2px solid white;
    }
    &._private {
      filter:invert(0);
      border: 2px solid white;
    }
  }
}

.return {
  display: flex;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
  span {
    flex: 1;
  }
  #bottomreturn {
    position: relative;
    margin-left: auto;
    bottom: 0;
    width: 85px;
    align-self: flex-end;
    margin: 0;
    @media screen and (max-width: 1200px) {
      margin-left: 0;
      margin-top: 50px;
      width: 50px;
      margin-bottom: 40px;
      align-self: flex-start;
    }
    cursor: pointer;
    &.return-sticky {
      position: sticky;
    }
    img {
      width: 85px;
      @media screen and (max-width: 1200px) {
        width: 50px;
      }
    }
  }
}
.border-download {
  border-top: 1px solid var(--darker);
  position: relative;
  margin: 100px 0;
  &::before,
  &::after {
    content: "";
    display: block;
    border-top: 1px solid var(--darker);
    margin-top: -1px;
    position: absolute;
  }
  &::before {
    margin-left: -130px;
    width: 130px;
  }
  &::after {
    width: 110px;
    right: 0;
    margin-right: -110px;
  }
}
</style>
