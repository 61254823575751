<template>
	<div class="notification-popup" v-if="notfifStorage !== 'false'">
		<span @click="closePopup" class="close"> <img src="../../assets/cta-close-black.svg"/></span>
		<div class="container">
			<div class="title">notifications</div>
			<div class="list">
				<template v-if="!mobile">
					<div v-for="(notif, key) in filterProducts" :key="key" class="notif-item">
						<template v-if="key <= 4">{{ notif.content }} has been updated in {{ notif.category }} ...</template>
					</div>
				</template>
				<template v-if="mobile">
					<div v-for="(notif, key) in filterProducts" :key="key" class="notif-item">
						<template v-if="key == 0">{{ notif.content }} has been updated in {{ notif.category }} ...</template>
					</div>
				</template>
			</div>
			<a class="link" @click="closePopup"><img src="../../assets/icon-cta-arrow-black-new.svg"/></a>
		</div>
	</div>
</template>

<script>
import { ref, computed, onMounted } from "@vue/runtime-core";
import isMobile from "is-mobile";
import axios from "axios";
export default {
	setup() {
		const show = ref(true);
		let listCookies = ref([]);
		let listNew = ref([]);
		let containsAll = ref(false);
		let listNewStorages = ref([]);
		const notfifStorage = ref(localStorage.getItem("notification"));
		function closePopup() {
			show.value = !show.value;
			showSnackbar();
			getCookies();
			localStorage.setItem("notification", false);
			containsAll.value = compareListNew;
			notfifStorage.value = "false";
		}

		onMounted(() => {
			if (notfifStorage.value === false) {
				containsAll.value = false;
			}
			if (compareListNew === false) {
				localStorage.setItem("notification", true);
				containsAll.value = true;
			}
		});

		const content = ref();
		const tags = ref(false);

		async function loadContent() {
			const result = await axios.get(`${process.env.VUE_APP_API}/downloads`, {
				headers: {
					Authorization: "Bearer B5p3PKmh86DJ79Y0SCa88OEpiUeYJZKcP4Ajt6vdKH9",
				},
			});
			content.value = Object.values(result?.data?.data);
			tags.value = result?.data?.has_tags;
		}

		loadContent();
		const filterProducts = computed(() => {
			/* eslint-disable */
			if (content.value) {
				return content.value.filter((item) => item.tag !== "");
			}
			/* eslint-enable */
		});

		const listSplitNumber = computed(() => {
			filterProducts.value.forEach((element) => {
				if (typeof element.link === "string") {
					const lastSegment = element.link.split("/").pop();
					listNew.value.push(parseInt(lastSegment));
				} else {
					listNew.value.push(element.link);
				}
			});
		});

		const listSplitCookies = computed(() => {
			getCookies();
		});

		const compareListNew = computed(() => {
			return (containsAll.value = listNew.value.every((r) => listNewStorages.value.includes(r)));
		});

		function showSnackbar() {
			filterProducts.value.forEach((element) => {
				if (typeof element.link === "string") {
					const lastSegment = element.link.split("/").pop();
					document.cookie = "file_" + lastSegment + "= true";
					listNew.value.push(parseInt(lastSegment));
				} else {
					document.cookie = "file_" + element.link + "= true";
					listNew.value.push(element.link);
				}
			});
		}
		function getCookies() {
			let cookieArr = document.cookie.split(";");
			// Loop through the array elements
			for (let i = 0; i < cookieArr.length; i++) {
				let cookiePair = cookieArr[i].split("=");
				if (cookiePair[0].indexOf("file_") !== -1) {
					listCookies.value.push(cookiePair[0]);
				}
			}

			listCookies.value.forEach((element) => {
				let el = element.replace(/\D/g, "");
				listNewStorages.value.push(parseInt(el));
			});
		}

		return {
			closePopup,
			content,
			filterProducts,
			show,
			tags,
			getCookies,
			containsAll,
			listNewStorages,
			listNew,
			notfifStorage,
			listSplitNumber,
			listSplitCookies,
			compareListNew,
			mobile: isMobile(),
		};
	},
};
</script>

<style lang="scss" scoped>
.notification-popup {
	position: fixed;
	top: 0;
	right: 0;
	width: 446px;
	background: $yellow2;
	z-index: 7;
	@media screen and (max-width: 1200px) {
		width: 50%;
		padding: 30px 0;
	}
	@media screen and (max-width: 374px) {
		width: 100%;
	}

	.close {
		width: 16px;
		height: 16px;
		padding: 0;
		position: absolute;
		right: 30px;
		top: 30px;
		z-index: 30;
		cursor: pointer;
		@media screen and (max-width: 1200px) {
			right: 26px;
		}
	}

	.more {
		font-size: 24px;
		line-height: 34px;
		font-weight: 700;
		color: #000;
		padding: 0;
	}

	.container {
		padding: 44px;
		@media screen and (max-width: 1200px) {
			padding-left: 16px;
			padding-right: 26px;
			padding-top: 30px;
			padding-bottom: 0;
		}
		.title {
			font-size: 24px;
			line-height: 34px;
			font-weight: 700;
			color: #000;
		}
		.list {
			padding-top: 20px;
			.notif-item {
				display: flex;
				flex-direction: column;
				padding-bottom: 10px;
				font-size: 18px;
				line-height: 23px;
				color: #000;
			}
		}
		.link {
			margin: 0;

			img {
				width: 24px;
				height: 24px;
				padding-top: 30px;
			}
		}
	}
}
</style>
