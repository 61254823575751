<template>
	<header
		:class="{
			'dark ': classList == 'dark ',
			' -open': store.state.isBurgerOpened,
		}"
	>
		<router-link to="/" exact class="logo-header">
			<img class="logo" src="../../assets/logo-renault-black.svg" alt="logo renault" />
		</router-link>
		<nav class="desktop">
			<router-link to="/brand-platform" class="link fundamental" v-slot="{ isActive }" :class="{ active: isActive }">
				brand platform
			</router-link>
			<router-link to="/fundamental-guidelines" class="link" v-slot="{ isActive }" :class="{ active: isActive }">
				fundamental guidelines
			</router-link>
			<router-link to="/showcase" class="link" exact v-slot="{ isActive }" :class="{ active: isActive }">
				showcase
			</router-link>
			<router-link to="/downloads" class="link" exact v-slot="{ isActive }" :class="{ active: isActive }">
				downloads
			</router-link>
			<router-link to="/notifications" class="link notification " exact v-slot="{ isActive }" :class="{ active: isActive }">
				<img src="../../assets/icon-notification-black.svg" alt="notifications" />
			</router-link>
		</nav>
		<div style="flex: 2" v-if="mobile"></div>
		<nav v-if="mobile">
			<router-link to="/notifications" class="link notification " exact v-slot="{ isActive }" :class="{ active: isActive }">
				<img src="../../assets/icon-notification-black.svg" alt="notifications" />
			</router-link>
		</nav>

		<nav class="mobile" :class="{ open: store.state.isBurgerOpened }" @click="store.methods.toggleBurger"></nav>
		<nav class="mobilenav" :class="{ hide: !store.state.isBurgerOpened }" v-if="mobile">
			<a href="/brand-platform"><h2>brand platform</h2></a>
			<a href="/fundamental-guidelines"><h2 class="fundamental">fundamental guidelines</h2></a>
			<a href="/showcase"><h2 class="fundamental">showcase</h2></a>
			<a href="/downloads"><h2 class="fundamental">downloads</h2></a>
			<div class="buttons">
				<DarkMode />
				<ButtonGridMode />
			</div>
		</nav>
	</header>
</template>

<script>
import { computed, inject, onMounted, ref, watchEffect } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import isMobile from "is-mobile";
import DarkMode from "@/components/organisms/DarkMode.vue";
import ButtonGridMode from "@/components/organisms/ButtonGridMode.vue";
export default {
	name: "Footer",
	components: {
		DarkMode,
		ButtonGridMode,
	},
	setup() {
		const store = inject("store");

		const route = useRoute();
		const theme = ref("");
		const isHidden = ref("");
		const timer = ref();
		function start() {
			if (route.name !== "Landing") return;
			isHidden.value = "";
			clearTimeout(timer.value);
			timer.value = setTimeout(() => {
				isHidden.value = "hide";
			}, 2000);
		}
		onMounted(() => {
			if (!isMobile()) {
				start();
				document.body.addEventListener("mousemove", () => {
					start();
				});
			}

			const body = document.getElementsByTagName("body")[0];
			if (!body.classList.contains("dark-mode")) {
				localStorage.setItem("theme", "light");
			}
			if (!body.classList.contains("grid-mode")) {
				localStorage.setItem("grid", "off");
			}
		});
		watchEffect(() => {
			if (route.name === "Landing" && !isMobile()) {
				timer.value = setTimeout(() => {
					isHidden.value = "hide";
				}, 2000);
			} else {
				clearTimeout(timer.value);
				isHidden.value = "";
			}
		});
		const classList = computed(() => {
			return `${theme.value} ${isHidden.value}`;
		});
		const { dataLayer } = window;
		function handleTrackingEvent() {
			dataLayer.push({
				brochureName: "Renault press kit",
				event: "download",
			});
		}

		return {
			store,
			publicPath: process.env.BASE_URL,
			handleTrackingEvent,
			theme,
			classList,
			start,
			mobile: isMobile(),
		};
	},
};
</script>

<style lang="scss" scoped>
body.dark-mode {
	header .logo {
		filter: invert(1);
	}
}

header {
	position: fixed;
	display: flex;
	align-items: center;
	background: var(--lighter);
	width: 100%;
	height: 100px;
	padding: 0 64px;
	font-size: 16px;
	font-weight: 700;
	box-sizing: border-box;
	border-bottom: 1px solid var(--darker);
	top: 0;
	z-index: 3;
	//transition: all 1s ease-in-out;

	.logo-header {
		margin: 0 64px;
		margin-left: 0;
	}

	.buttons {
		display: flex;
		flex-direction: column;
		margin-top: 59px;
	}

	&.dark {
		background-color: var(--darker);
		border-bottom: 1px solid var(--lighter);
		color: var(--lighter);
		img {
			filter: invert(1);
		}
		&.-open {
			background-color: var(--lighter);
			border-bottom: 1px solid var(--darker);
			color: var(--darker);
			img {
				filter: invert(0);
			}
			nav {
				&.mobile {
					background: var(--lighter);
					border-left: 1px solid var(--darker) !important;
					&::after {
						background-color: var(--darker);
					}
					&::before {
						background-color: var(--darker);
					}
				}
			}
		}
		nav {
			border-left: 1px solid var(--lighter) !important;
			&.mobile {
				background: var(--darker);
				border-left: 1px solid var(--lighter);
				&::after {
					background-color: var(--lighter);
				}
				&::before {
					background-color: var(--lighter);
				}
			}
		}
		a {
			color: var(--darker);
			&.link {
				color: var(--lighter);
				&::after {
					background-color: var(--lighter);
				}
			}
		}
	}
	img {
		cursor: pointer;
	}
	.logo {
		height: 65px;
		width: 200px;
		margin-right: 6px;
	}
	nav {
		&.hide {
			display: none;
		}
		a {
			font-weight: 700;
			text-decoration: none;
			display: flex;
			flex-direction: column;
			margin-top: 8px;
			&::after {
				content: "";
				width: 0%;
				height: 4px;
				background-color: var(--darker);
				transition: all 1s ease-in-out;
				margin-top: 8px;
			}
			&:hover {
				&::after {
					width: 100%;
				}
			}
			&.router-link-active {
				text-decoration: none;
				&::after {
					width: 100%;
				}
			}
		}
		.comingsoon {
			color: var(--grey);
			margin-top: -2px;
			&:hover {
				margin-top: 18px;
				&::after {
					content: "soon !";
					font-size: 16px;
					background-color: var(--yellow2);
					padding-left: 13px;
					color: var(--darker);
					font-weight: 700;
				}
			}
		}
		&.desktop {
			.date {
				margin: 0 32px;
			}
			display: flex;
			align-items: center;
			justify-content: flex-end;
			flex-grow: 1;
			height: 100%;
			border-left: 1px solid var(--darker);
		}
		&.mobile {
			display: none;
			position: relative;
			width: 74px;
			height: 100%;
			background-color: var(--lighter);
			border: none;
			//transition: all 1s ease-in-out;
			border-left: 1px solid var(--darker);
			&::after {
				content: "";
				position: absolute;
				top: 24px;
				display: block;
				width: 24px;
				height: 2px;
				background-color: var(--darker);
				transform: translate(-50%, -50%);
				margin-left: 50%;
				transition: all 1s ease-in-out;
			}
			&::before {
				content: "";
				position: absolute;
				bottom: 24px;
				display: block;
				width: 24px;
				height: 2px;
				background-color: var(--darker);
				transform: translate(-50%, -50%);
				margin-left: 50%;
				transition: all 1s ease-in-out;
			}
			&.open {
				&::after {
					top: 29px;
					transform: rotate(-45deg);
					margin-left: 33%;
				}
				&::before {
					bottom: 29px;
					transform: rotate(45deg);
					margin-left: 33%;
				}
			}
		}
	}
}
@media screen and (max-width: 1200px) {
	header {
		height: 61px;
		width: 100%;
		padding: 0 0 0 32px;
		justify-content: space-between;
		.logo-header {
			margin: 0 15px;
			margin-left: 0;
		}
		&.dark {
			button {
				&.emphaziz {
					background-color: var(--darker);
					a {
						color: var(--lighter);
					}
					.download-img {
						filter: invert(0);
					}
				}
			}
		}
		.logo {
			height: 31px;
			width: unset;
			margin-right: 32px;
		}
		nav {
			.date {
				margin: 0;
			}
			border: none;
			margin: 0;
			padding: 0;
			&.mobile {
				display: block;
			}
			&.desktop {
				display: none;
			}
			&.mobilenav {
				background-color: var(--lighter);
				position: fixed;
				height: 100vh;
				width: 100%;
				z-index: 33;
				padding-left: 20px;
				padding-right: 25px;
				padding-top: 50px;
				overflow: auto;
				top: 0;
				left: 0;
				margin-top: 61px;
				box-sizing: border-box;
				//transition: all 1s ease-in-out;
				&.hide {
					left: -100%;
					opacity: 0;
				}
				a {
					text-decoration: none;
					margin: 0;
					margin-bottom: 20px;

					&::after {
						content: "";
						width: 0%;
						height: 0;
						margin-top: 0;
					}

					h2 {
						font-size: 45px;
						margin-block-end: 0;
						margin-block-start: 0;

						line-height: 50px;
						&.fundamental {
							margin-block-start: 0;
						}
					}
				}
			}
		}

		.emphaziz-container {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			background-color: var(--lighter);
			button {
				margin: 13px auto;
				width: 100%;
				&.emphaziz {
					.download-img {
						background-image: url("../../assets/icon-download-white.svg");
						border: 2px solid var(--lighter);
					}
				}
			}
		}
	}
}

.notification {
	&.router-link-active {
		&::after {
			width: 4px !important;
		}
	}
	&::after {
		transition: none;

		margin: 8px auto 0;
	}
	&:hover {
		&::after {
			width: 4px !important;
			margin: 8px auto 0;
		}
	}
}
</style>
