<template>
  <div class="container">
    <span v-if="items.length > 1" class="items_number"
      >{{ items.length }} pictures
    </span>
    <swiper
      class="parallax-slider"
      :slidesPerView="'auto'"
      :scrollbar="{ draggable: true }"
      :class="{ col1: ctype === 6, 'col1-slide': ctype === 12, 'test': items.length > 1  }"
      :pagination="items.length > 1 ? { type: 'fraction' } : false"
      :navigation="items.length > 1 ? true : false"
    >
      <swiper-slide  v-for="(media, key) in items" :key="key" class="parallax-slide" :class="{ col: ctype === 6, 'col-slide': ctype === 12, 'coltest': items.length > 1 }">
          <img :src="media" :style="{
          left: `-${current}00%`,
          'margin-right': ctype === 12 ? '120' : '0px'
        }">
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import SwiperCore, { Scrollbar, Pagination, Navigation } from "swiper";

import { Swiper, SwiperSlide } from "swiper/vue";



SwiperCore.use([Pagination, Scrollbar, Navigation]);

import { ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
import isMobile from "is-mobile";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  name: "MediaSlider",
  props: { media: Object, ctype: { required: true, type: Number } },
  setup(props) {
    const current = ref(0);
    const isMobileDevice = isMobile();
    const items = computed(() =>
      props.media ? Object.values(props.media).filter((v) => v.length) : []
    );

    function changeSlide() {
      if (current.value !== items.value.length - 1) {
        current.value++;
      } else {
        current.value = 0;
      }
    }

    return { current, items, changeSlide, isMobileDevice };
  },
};
</script>

<style lang="scss">
//import swiper css and dependencies
@import "swiper/swiper.scss";
@import "swiper/components/scrollbar/scrollbar.scss";
@import "swiper/components/navigation/navigation.scss";
@import "swiper/components/pagination/pagination.scss";
.parallax-slider {
  &.test {
    @media screen and (max-width: 1200px) {
      margin-bottom: 80px;
    }
  }
  &.col1-slide {
    margin-left: 32px;
    margin-right: 32px;
    .swiper-button-prev {
      left: 32px;
    }
    .swiper-button-next {
      left: 92px;
    }
    .swiper-slide-prev {
      margin-left: -40px;
      @media screen and (max-width: 1200px) {
        margin-left: -10px;
      }
    }

    .swiper-slide-next {
      padding-left: 20px;
      margin-left: -60px;
      background: var(--lighter);
      @media screen and (max-width: 1200px) {
        margin-left: -20px;
        padding-left: 10px;
      }
    }
  }
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  font-size: 21px;
  font-weight: 600;
  span {
    padding: 0 5px;
  }
}
.swiper-pagination {
  display: flex;
  bottom: -40px;
   left: calc(20% - 184px);
  
  z-index: 2;

  @media screen and (max-width: 1200px) {
        left: 25px;
     }
}
.swiper-container-horizontal > .swiper-scrollbar {
  bottom: -30px;
  height: 4px;
  left: calc(20% - 184px + 110px);
  width: 75%;
  z-index: 2;
  background-color: #bababa;
  @media screen and (max-width: 1200px) {
    left: 20px;
    width: calc(100% - 45px);
    bottom: -63px;
    margin-top: 15px;
  }
}
.swiper-scrollbar-drag {
  background: var(--darker);
  border-radius: 0;
}

body.dark-mode{
  .swiper-button-prev {
    &:after {
      content: url("../../assets/arrow-right.svg");
      transform: rotate(90deg);
      filter: invert(1);
    }
  }
  .swiper-button-next {
    &:after {
      content: url("../../assets/arrow-right.svg");
      transform: rotate(-90deg);
      filter: invert(1);
    }
  }
}

.swiper-button-prev,
.swiper-button-next {
  bottom: 0;
  background: var(--lighter);
  color: var(--darker);
  width: 60px;
  height: 60px;
  top: auto;
  left: 0;
  z-index: 2;
  &:after {
    font-size: 20px;
    font-weight: 700;
  }
}
.swiper-button-prev {
  &:after {
    content: url("../../assets/arrow-right.svg");
    transform: rotate(90deg);
  }
}
.swiper-button-next {
  left: 60px;
  border-left: 1px solid var(--darker);
  &:after {
    content: url("../../assets/arrow-right.svg");
    transform: rotate(-90deg);
  }
}

.container {
  box-sizing: border-box;
  min-width: 100%;
  position: relative;
}

.parallax-slider {
  position: initial;
}

.items_number {
  font-size: 21px;
  font-weight: 700;
  padding-bottom: 20px;
  @media screen and (min-width: 1440px) {
        padding-left: calc(20% - 184px);
     }
}

.swiper-slide{
  height: auto;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.col1 {
  margin: 0 32px;
}
.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity: 1;
}
</style>
