<template>
  <div class="downloads-page">
    <AuthPopIn
      :isPopInVisible="isPopInVisible"
      @hidePopIn="isPopInVisible = false"
      :fileNumber="fileNumber"
    ></AuthPopIn>
    <div v-if="snackbar" class="snackbar snackbar-download">
      the download are in progress.
    </div>
    <div class="downloads-container" v-if="filterProducts || content">
      <navigation-download
        @categorySelected="categorySelected"
        @brandSelected="brandSelected"
        v-click-outside="nameOfCustomEventToCall"
        @brandList="showToggle"
        @categoriesList="showToggle"
        :show="toogleOpen"
      ></navigation-download>
      <template v-for="(download, index) in filterProducts" :key="index">
        <div
          class="download"
          :class="{ isActive: getCookie(download.link) == true }"
        >
          <div class="mobile">
            <div class="date">
              <span>{{ download.date }}</span>
              <span class="tag" :class="{ show: download.tag !== '' }">{{
                download.tag
              }}</span>
            </div>
            <div class="brand">{{ download.brand }}</div>
            <div class="category">{{ download.category }}</div>
            <div class="content">{{ download.content }}</div>
          </div>
          <div class="mobile">
            <div class="link" v-if="download.private">
              <span>
                <a
                  @click="handleDownload($event, download.link)"
                  :href="download.link"
                  class="private"
                >
                </a>
              </span>
            </div>
            <div class="link" v-else>
              <span>
                <a
                  class="public"
                  :href="download.link"
                  @click="showSnackbar(download.link)"
                >
                </a>
              </span>
            </div>
          </div>
        </div>
      </template>
      <div class="pagination" v-if="content.length > 16">
        <span>{{ currentPage }}</span
        ><span>/</span><span>{{ getPageCount }}</span>
        <img
          src="../../assets/arrow-filter-black.svg"
          class="previous"
          @click="clickCallback(currentPage - 1)"
        />
        <img
          src="../../assets/arrow-filter-black.svg"
          class="next"
          @click="clickCallback(currentPage + 1)"
        />
      </div>
      <div class="noresult" v-if="content.length == 0">
        <span>No result</span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "@vue/runtime-core";
import axios from "axios";
import AuthPopIn from "@/components/organisms/AuthPopIn";
import NavigationDownload from "@/components/molecules/NavigationDownload";
export default {
  name: "Download",
  components: {
    AuthPopIn,
    NavigationDownload,
  },

  setup() {
    const content = ref();

    async function loadContent() {
      const result = await axios.get(`${process.env.VUE_APP_API}/downloads`, {
        headers: {
          Authorization: "Bearer B5p3PKmh86DJ79Y0SCa88OEpiUeYJZKcP4Ajt6vdKH9",
        },
      });
      content.value = Object.values(result?.data?.data);
    }

    // count number of page
    const parPage = ref(16);
    const currentPage = ref(1);
    const getPageCount = computed(() => {
      if (categoryValue.value === null && brandValue.value === null) {
        return Math.ceil(content.value.length / parPage.value);
      } else {
        return Math.ceil(filterProducts.value.length / parPage.value);
      }
    });

    loadContent();

    //change page table
    function clickCallback(pageNum) {
      if (pageNum === 0) return;
      if (pageNum <= getPageCount._value) {
        currentPage.value = Number(pageNum);
      }
    }

    const filterProducts = computed(() => {
      const current = currentPage.value * parPage.value;
      const start = current - parPage.value;
      if (categoryValue.value === null && brandValue.value === null) {
        return content.value?.slice(start, current);
      }
      if (categoryValue.value !== null && brandValue.value === null) {
        return content.value
          .filter((item) => item.category === categoryValue.value)
          ?.slice(start, current);
      }
      if (brandValue.value !== null && categoryValue.value === null) {
        return content.value
          .filter((item) => item.brand === brandValue.value)
          ?.slice(start, current);
      }
      if (brandValue.value !== null && categoryValue.value !== null) {
        return content.value
          .filter((item) => item.category === categoryValue.value)
          .filter((item) => item.brand === brandValue.value)
          .slice(start, current);
      }
    });

    const categoryValue = ref(null);
    function toggleCategory() {
      categoryValue.value = !categoryValue.value;
    }
    const brandValue = ref(null);
    function toggleBrand() {
      brandValue.value = !brandValue.value;
    }

    const toogleOpen = ref();
    function toggleShow() {
      toogleOpen.value = !toogleOpen.value;
    }

    function nameOfCustomEventToCall() {
      if (toogleOpen.value === true) {
        toogleOpen.value = false;
      }
    }
    function getCookie(name) {
      // Split cookie string and get all individual name=value pairs in an array
      let cookieArr = document.cookie.split(";");
      // Loop through the array elements
      for (let i = 0; i < cookieArr.length; i++) {
        let cookiePair = cookieArr[i].split("=");
        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if (typeof name === "string") {
          const lastSegment = name.split("/").pop();
          if ("file_" + lastSegment == cookiePair[0].trim()) return true;
        }
        if ("file_" + name == cookiePair[0].trim()) {
          return true;
        }
      }
      // Return null if not found
      return null;
    }

    const isPopInVisible = ref(false);
    const fileNumber = ref(0);
    function handleDownload(e, isPrivate) {
      if (isPrivate) {
        e.preventDefault();
        fileNumber.value = e.target.getAttribute("href");
        isPopInVisible.value = true;
      }
    }

    const snackbar = ref(false);
    function showSnackbar(link) {
      snackbar.value = true;
      const lastSegment = link.split("/").pop();
      document.cookie = "file_" + lastSegment + "= true";
      setInterval(() => {
        snackbar.value = false;
      }, 8000);
    }

    return {
      content,
      handleDownload,
      isPopInVisible,
      fileNumber,
      toggleCategory,
      toggleBrand,
      parPage,
      currentPage,
      getPageCount,
      clickCallback,
      brandValue,
      categoryValue,
      nameOfCustomEventToCall,
      toogleOpen,
      toggleShow,
      filterProducts,
      snackbar,
      showSnackbar,
      getCookie,
    };
  },
  methods: {
    brandSelected(val) {
      this.brandValue = val;
      this.currentPage = 1;
    },
    categorySelected(value) {
      this.categoryValue = value;
      this.currentPage = 1;
    },

    showToggle(val) {
      this.toogleOpen = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.downloads-page {
  display: flex;
  padding-top: 100px;
  min-height: 100vh;
  padding-bottom: 70px;
  width: 100%;
  background-color: var(--lighter);
  box-sizing: border-box;
  @media screen and (max-width: 1200px) {
    padding-top: 61px;
    padding-bottom: 70px;
  }

  .downloads-container {
    width: 100%;
  }

  .download {
    display: flex;
    padding: 22.5px 130px;
    font-weight: 700;
    border-bottom: 1px solid var(--darker);
    @media screen and (max-width: 1200px) {
      flex-direction: row;
      align-items: center;
      padding: 20px;
    }
    &.isActive {
      font-weight: normal;
    }
    & > div {
      flex: 1;
      @media screen and (max-width: 1200px) {
        flex: 0;
      }
    }
    .mobile {
      &:first-child {
        flex: 5;
        display: flex;
        flex-direction: row;
        @media screen and (max-width: 1200px) {
          flex-direction: column;
        }
        & > div {
          flex: 1;
        }
      }
      @media screen and (max-width: 1200px) {
        flex: 1;
      }
    }
  }
}

.link {
  text-align: right;
  a {
    margin: 0;
  }
  span {
    padding: 0;
    align-items: flex-end;
  }
  .public {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background-size: 24px;
    background-position: center;
    filter: invert(1);
    box-sizing: border-box;
    border: 2px solid var(--lighter);
    background-image: url("../../assets/icon-download-white.svg");
    &:hover {
      background-position-y: 22px;
      transition: all 0.8s ease-in-out;
    }
  }
  .private {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background-size: 24px;
    background-position: center;
    filter: invert(1);
    box-sizing: border-box;
    border: 2px solid var(--lighter);
    background-image: url("../../assets/icon-lock-white.svg");
    &:hover {
      background-position-y: 22px;
      transition: all 0.8s ease-in-out;
    }
  }
}

body.dark-mode {
  .link .public {
    background-image: url("../../assets/icon-download-black.svg");
  }
  .link .private {
    background-image: url("../../assets/icon-lock-black.svg");
  }
  .pagination {
    .next,
    .previous {
      filter: invert(1);
    }
  }
}

.pagination {
  padding: 0;
  padding-top: 40px;
  padding-left: 130px;
  display: flex;
  @media screen and (max-width: 1200px) {
    padding-left: 20px;
  }
  span {
    padding: 0;
    padding-right: 5px;
    font-weight: 700;
  }
  .previous {
    transform: rotate(-90deg);
    margin-right: 50px;
    margin-left: 35px;
    cursor: pointer;
  }
  .next {
    transform: rotate(90deg);
    cursor: pointer;
  }
}

.date {
  flex-direction: row;
  display: flex;
  span {
    padding: 0;
    &.tag {
      margin-left: 22px;
      font-weight: 700;
      font-size: 16px;
      padding: 5px;
      line-height: 1;
    }
    &.show {
      color: var(--fixed-darker);
      background: var(--yellow2);
    }
  }
}

.noresult {
  span {
    align-items: center;
  }
}

.snackbar-download {
  padding: 16px;
  line-height: 1;
}
</style>
