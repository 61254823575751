<template>
  <button
    @click="darkMode"
    class="dark-button"
    :class="{ 'is-active': value == 'dark', 'is-invert': invert }"
  >
    <span> dark:</span
    ><span class="active">{{ value == "dark" ? "on" : "off" }}</span>
  </button>
</template>

<script>
import { ref, onMounted, watch } from "@vue/runtime-core";
export default {
  props: {
    invert: { type: Boolean, required: false, default: false },
  },
  setup() {
      const value = ref(localStorage.getItem("theme"));
      watch(value, (newX) => {
          const allDark = document.querySelectorAll('.dark-button');

          allDark.forEach(dark => {
              if (newX === 'dark') {
                  dark.classList.add('is-active')
                  dark.children[1].innerHTML = 'on'
              }
              if (newX === 'light') {
                  dark.classList.remove('is-active')
                  dark.children[1].innerHTML = 'off'
              }
          })
      });
    onMounted(() => {
      const body = document.getElementsByTagName("body")[0];
      if (value.value === "light") {
        localStorage.setItem("theme", "light");
        value.value = "light";
        body.classList.remove("dark-mode");
      }
      if (value.value === "dark") {
        localStorage.setItem("theme", "dark");
        value.value = "dark";
        body.classList.add("dark-mode");
      }
    });

    const darkMode = () => {
      const body = document.getElementsByTagName("body")[0];
      if (value.value === "dark") {
        value.value = "light";
        localStorage.setItem("theme", "light");
        body.classList.remove("dark-mode");
      } else {
        value.value = "dark";
        localStorage.setItem("theme", "dark");
        body.classList.add("dark-mode");
      }
    };

    return { darkMode, value };
  },
};
</script>

<style lang="scss" scoped>
.dark-button {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  padding: 0%;
  text-align: center;
  display: block;
  margin: 0;

  &.is-invert {
    filter: invert(1);
    &.is-active {
      filter: invert(0);
      background: $yellow2;
    }
  }
@media screen and (min-width: 1200px) {
  &:hover {
    background: #FFF26E;
    color: #000;
  }
  }
  &.is-active {
    background: $yellow2;
  }
  span {
    font-size: 12px;
    line-height: 12px;
    padding: 0;
    font-weight: normal;
  }
  .active {
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
  }
}

body.dark-mode {
    .dark-button {
         &.is-active {
         @media screen and (min-width: 1200px) {
          &:hover {
           background: #FFF;
          }
         }
         }
    }
}
</style>