import { reactive } from "@vue/reactivity";

const state = reactive({
  isBurgerOpened: false
})

const methods = {
  toggleBurger () {
    state.isBurgerOpened = !state.isBurgerOpened
  }
}

export default {
  state,
  methods
}
