<template>
  <div class="download-container">
  <div
    v-for="(down, index) in mostDownloads"
    :key="index"
    class="download __column"
    :class="{isActive: getCookie(down.link) == true }"
    :style="'grid-row:' + (17 + index)"
  >
    <div class="desktop">
      <span style="grid-column: 1/1;'"
        >{{ new Date(down.date).toLocaleDateString("fr-FR") }}
      </span>
      <span style="grid-column: 2/2">{{ down.brand }}</span>
      <span style="grid-column: 3/3">{{ down.category }}</span>
      <span style="grid-column: 4/4">{{ down.content }}</span>
      <span  class="download-image" :style="'grid-column: 5/5;grid-row:17+' + index"
        ><a class="download-img" :href="down.link"  @click="downloadFile(link)"></a
      ></span>
    </div>
    <div class="mobile">
      <span style="grid-column: 1/1;'"
        >{{ new Date(down.date).toLocaleDateString("fr-FR") }}
      </span>
      <span style="grid-column: 2/2">{{ down.brand }}</span>
      <span style="grid-column: 3/3">{{ down.category }}</span>
      <span style="grid-column: 4/4">{{ down.content }}</span>
    </div>
    <div class="mobile">
      <span :style="'grid-column: 5/5;grid-row:17+' + index"
        ><a class="download-img" :href="down.link"  @click="downloadFile(link)"></a
      ></span>
    </div>
  </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import axios from "axios";
export default {
  name: "MostDownload",
  setup() {
    const mostDownloads = ref();

    async function loadContent() {
      const result = await axios.get(`${process.env.VUE_APP_API}/file_counts`, {
        headers: {
          Authorization: "Bearer B5p3PKmh86DJ79Y0SCa88OEpiUeYJZKcP4Ajt6vdKH9",
        },
      });

      mostDownloads.value = result?.data?.data;
    }
    loadContent();

    function getCookie(name) {
      // Split cookie string and get all individual name=value pairs in an array
      let cookieArr = document.cookie.split(";");
      // Loop through the array elements
      for(let i = 0; i < cookieArr.length; i++) {
        let cookiePair = cookieArr[i].split("=");
        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if (typeof name === 'string' ) {
          const lastSegment = name.split("/").pop();
          if("file_"+ lastSegment == cookiePair[0].trim()) return true
        }
        if("file_"+name == cookiePair[0].trim()) {
            return true
        }
      }
      // Return null if not found
      return null;
    }

    function downloadFile (link) {
      const lastSegment = link.split("/").pop();
      document.cookie = "file_"+ lastSegment +'= true';

    }

    return { mostDownloads, getCookie, downloadFile };
  },
};
</script>
<style lang="scss" scoped>
.download {
  grid-column: 1/6;
  border-top: 1px solid var(--darker);
  position: relative;
  font-weight: 700;
  &.isActive {
    font-weight: normal;
  }
  &::before,
  &::after {
    content: "";
    display: block;
    border-top: 1px solid var(--darker);
    border-bottom: 1px solid var(--darker);
    margin-top: -1px;
    min-height: 100%;
    position: absolute;
  }

  &::before {
    content: "";
    display: block;
    margin-left: -130px;
    width: 130px;
    border-top: 1px solid var(--darker);
    margin-top: -1px;
  }
  &::after {
    content: "";
    display: block;
    width: 110px;
    border-top: 1px solid var(--darker);
    margin-top: -1px;
    position: absolute;
    right: 0;
    margin-right: -110px;
  }
  &.__column {
    display: grid;
    grid-template-columns: repeat(5, 17.5fr);
    height: auto;
    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(2, 50fr);
    }

    &:last-child {
      margin-bottom: 100px;
      border-bottom: 1px solid var(--darker);
    }

    span {
      padding: 25px 0;
      font-size: 21px;
      line-height: 34px;
      @media screen and (max-width: 1200px) {
        padding: 0;
        grid-column: 1/1 !important;
        font-size: 16px;
        line-height: 23px;
      }
      &:nth-child(5) {
        align-items: flex-end;
        justify-content: center;
        @media screen and (max-width: 1200px) {
          grid-column: 2/2 !important;
        }
      }
    }
    .download-img {
        //pointer-events: none;
        margin-left: 10px;
        background-image: url("../../assets/icon-download-white.svg");
        width: 24px;
        height: 24px;
         border-radius: 24px;
        background-size: 24px;
        background-position: center;
        filter:invert(1);
        box-sizing: border-box;
        border: 2px solid var(--lighter);
        @media screen and (max-width: 1200px) {
          margin-right: 0;
        }
      }
      &:hover {
        .download-img {
          //pointer-events: none;
          background-position-y: 22px;
          transition: all 0.8s ease-in-out;
        }
      }
  }
}
body.dark-mode{
  .download.__column .download-img {
     filter:invert(0);
     border: 2px solid white;

  }
}

.mobile {
  display: none;
  @media screen and (max-width: 1200px) {
    display: block;
    padding: 20px 0;
    &:nth-child(2) {
      grid-column: 1;
    }
    &:nth-child(3) {
      align-self: center;
      margin: 0 auto;
      margin-right: 0;
      grid-column: 2;
    }
  }
}
.desktop {
  grid-template-columns: repeat(5, 17.5fr);
  grid-column: 1/6;
  display: grid;
  @media screen and (max-width: 1200px) {
    display: none;
  }
}
.download-container {
  grid-column: 1/6;
  position: relative;
  grid-row: 16;
  margin-top: 100px;

}


</style>
