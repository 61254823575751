<template>
  <div class="container container-image">
    <div class="element">
      <div id="yellow" class="colorbox">
        <p class="title">Yellow</p>
        <p class="values">
          <span class="element-color"><span class="subtitle">RGB</span> 239 223 0</span>
          <span class="element-color"><span class="subtitle">CMYK</span>5 0 100 0</span>
          <span class="element-color"><span class="subtitle">HEX</span> EFDF00</span>
        </p>
      </div>
      <div id="white" class="colorbox">
        <p class="title">White</p>
        <p class="values">
           <span class="element-color"><span class="subtitle">RGB</span> 255 255 255</span>
          <span class="element-color"><span class="subtitle">CMYK</span>0 0 0 0</span>
          <span class="element-color"><span class="subtitle">HEX</span> FFFFFF</span>
        </p>
      </div>
      <div id="black" class="colorbox">
        <p class="title">Black</p>
        <p class="values">
           <span class="element-color"><span class="subtitle">RGB</span> 0 0 0</span>
          <span class="element-color"><span class="subtitle">CMYK</span>0 0 0 100</span>
          <span class="element-color"><span class="subtitle">HEX</span> 000000</span>
        </p>
      </div>
    </div>
    <div class="element">
      <div id="grey-light" class="colorbox">
        <p class="title">Grey Light</p>
        <p class="values">
           <span class="element-color"><span class="subtitle">RGB</span> 217 217 214</span>
          <span class="element-color"><span class="subtitle">CMYK</span>0 0 5 20</span>
          <span class="element-color"><span class="subtitle">HEX</span> D9D9D6</span>
        </p>
      </div>
      <div id="grey-mind" class="colorbox">
        <p class="title">Grey Mid</p>
        <p class="values">
           <span class="element-color"><span class="subtitle">RGB</span> 187 188 188</span>
          <span class="element-color"><span class="subtitle">CMYK</span>0 0 5 40</span>
          <span class="element-color"><span class="subtitle">HEX</span> BBBCBC</span>

        </p>
      </div>
      <div id="grey-dark" class="colorbox">
        <p class="title">Grey Dark</p>
        <p class="values">
           <span class="element-color"><span class="subtitle">RGB</span> 136 139 141</span>
          <span class="element-color"><span class="subtitle">CMYK</span>5 0 0 60</span>
          <span class="element-color"><span class="subtitle">HEX</span> 888B8D</span>

        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  name: "ColorHover",
  el: "#values",
  setup() {
    const index = ref();

    function emitBusiness(data) {
      index.value = data;
    }

    return { index, emitBusiness };
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 0 32px;
  width: 100%;
  box-sizing: border-box;
  
}
.element {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  .colorbox {
    flex: 1;
    height: 303px;
    color: dark;
    padding-top: 70px;
    padding-left: 92px;
    transition: all 1s ease-in-out;
    .values {
      opacity: 0;
      margin-top: 0.5em;
      transition: all 0.5s ease-in-out;
    }
    &:hover {
      flex-grow: 2;
      padding-left: 93px;
      .values {
        opacity: 1;
      }
    }
    .title {
      font-weight: 700;
      margin-bottom: 0.5em;
    }
    .subtitle {
      font-weight: 700;
      padding: initial;
      margin-right: 10px;
    }
    .element-color {
      padding: 0;
      display: flex;
      flex-direction:  row;
    }
  }
  #yellow {
    background: var(--yellow);
    color: var(--fixed-darker);
  }
  #black {
    background: var(--fixed-darker);
    color: var(--fixed-lighter);
  }
  #white {
    background: var(--fixed-lighter);
    color: var(--fixed-darker);
  }
  #grey-light {
    background: var(--grey-light);
    color: var(--fixed-darker);
  }
  #grey-mind {
    background: var(--grey);
    color: var(--fixed-darker);
  }
  #grey-dark {
    background: var(--grey-dark);
    color: var(--fixed-lighter);
  }
}

@media screen and (max-width: 500px) {
  .element {
    flex-direction: column;
    .colorbox {
      width: 100%;
      padding: 32px;
      box-sizing: border-box;
      .values {
        opacity: 1;
      }
      &:hover {
        padding-left: 68px;
      }
    }
  }
}
</style>
