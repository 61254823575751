<template>
  <div>
    <m-gradient-slider></m-gradient-slider>
  </div>
</template>

<script>
import MGradientSlider from '@/components/molecules/MGradientSlider'

export default {
  name: "GradientColor",
  components: { MGradientSlider }
}
</script>

<style lang="scss" scoped>

</style>