import { createRouter, createWebHistory } from 'vue-router'
import Landing from '@/components/templates/Landing' 
import BrandPlatform from '@/components/templates/BrandPlatform' 
import PComponents from '@/components/pages/PComponents' 
import MaintenancePage from '@/components/templates/MaintenancePage' 
import LegalNotice from '@/components/templates/LegalNotice' 
import TermsOfService from '@/components/templates/TermsOfService' 
import CookiePolicy from '@/components/templates/CookiePolicy' 
import PersonalData from '@/components/templates/PersonalData' 
import PageNotFound from '@/components/templates/PageNotFound' 
import Showcase from '@/components/templates/Showcase' 
import Downloads from '@/components/templates/Downloads' 
import Notifications from "@/components/templates/Notifications"; 

const routes = [
	{
		path: "/maintenance",
		name: "maintenancePage",
		component: MaintenancePage,
	},
	{
		path: "/",
		name: "Landing",
		component: Landing,
		redirect: process.env.VUE_APP_MAINTENANCE === "true" ? "/maintenance" : null,
	},
	{
		path: "/brand-platform",
		name: "BrandPlatform",
		component: BrandPlatform,
		redirect: process.env.VUE_APP_MAINTENANCE === "true" ? "/maintenance" : null,
	},
	{
		path: "/fundamental-guidelines",
		alias: ["/fundamental-guidelines/:brand/:category", "/fundamental-guidelines/:brand"],
		name: "PComponents",
		component: PComponents,
		props: true,
		redirect: process.env.VUE_APP_MAINTENANCE === "true" ? "/maintenance" : null,
	},
	{
		name: "LegalNotice",
		path: "/legal-notice",
		component: LegalNotice,
		redirect: process.env.VUE_APP_MAINTENANCE === "true" ? "/maintenance" : null,
	},
	{
		name: "Showcase",
		path: "/showcase",
		component: Showcase,
		redirect: process.env.VUE_APP_MAINTENANCE === "true" ? "/maintenance" : null,
	},
	{
		name: "Downloads",
		path: "/downloads",
		component: Downloads,
		redirect: process.env.VUE_APP_MAINTENANCE === "true" ? "/maintenance" : null,
	},
	{
		name: "Notifications",
		path: "/notifications",
		component: Notifications,
		redirect: process.env.VUE_APP_MAINTENANCE === "true" ? "/notifications" : null,
	},
	{
		name: "TermsOfService",
		path: "/terms-of-service",
		component: TermsOfService,
		redirect: process.env.VUE_APP_MAINTENANCE === "true" ? "/maintenance" : null,
	},
	{
		name: "CookiePolicy",
		path: "/cookie-policy",
		component: CookiePolicy,
		redirect: process.env.VUE_APP_MAINTENANCE === "true" ? "/maintenance" : null,
	},
	{
		name: "PersonalData",
		path: "/personal-data",
		component: PersonalData,
		redirect: process.env.VUE_APP_MAINTENANCE == true ? "/maintenance" : null,
	},
	{
		path: "/:pathMatch(.*)",
		name: "PageNotFound",
		component: PageNotFound,
		redirect: process.env.VUE_APP_MAINTENANCE == true ? "/maintenance" : null,
	},
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: () => {
    document.getElementById('app').scrollIntoView();
    if ( window.location.pathname !== '/showcase'){
      document.querySelector('#app').style.position = 'relative';
   }
   
   
  }

})



export default router
