import { watch } from "@vue/runtime-core"
import axios from "axios"

const { ref } = require("@vue/reactivity")

export const useAxios = (endpoint) => {
  const data = ref([])
  const error = ref()
  const loading = ref(false)
  
  watch(() => {
    const fetch = async () => {
      loading.value = true
      try {
        const res = await axios.get(`${process.env.VUE_APP_API}/${endpoint}`, { headers: { 'Authorization': 'Bearer B5p3PKmh86DJ79Y0SCa88OEpiUeYJZKcP4Ajt6vdKH9' } })
        data.value = res.data?.data
       
      } catch (err) {
        if(window.location.pathname !=='/maintenance') {
          window.location.href = '/maintenance'
        }
        if(err.response.status == '503' ) {
          //handle error of type
         //window.location = '/maintenance'
      }
      } finally {
        loading.value = false
      }
      loading.value = !loading.value
    }
    fetch()
  })

  return { data, error, loading }
}
