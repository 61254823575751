<template>
  <div class="copy-link">
    <div v-if="test" class="snackbar">link copied</div>
    <a @click="copyURL()" class="link">copylink </a>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";

export default {
  name: "MCopyLink",
  props: { url: { type: String, required: true } },
  setup(props) {
    const test = ref(false);

    async function copyURL() {
      // let url = window.location.href;
      let origin = window.location.origin
      await navigator.clipboard.writeText(origin + props.url);
      test.value = true;
      setInterval(() => {
        test.value = false;
      }, 8000);
    }

    return { test, copyURL };
  },
};
</script>


<style lang="scss" scoped>
.copy-link {
  padding-left: 32px;
  @media screen and (max-width: 1200px) {
    padding-top: 32px;
  }
  .link {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    padding: 0;
    margin: 0;
    &::after {
      content: "";
      margin-left: 10px;
      background-image: url("../../assets/icon-cta-arrow-black.svg");
      background-size: 10px;
      background-position: center;
      overflow: hidden;
      width: 10px;
      height: 10px;
    }
    &:hover {
      &::after {
        background-position-y: -10px;
        transition: all 0.8s ease-in-out;
      }
    }
  }
}

body.dark-mode {
  .copy-link {
    .link {
      &::after {
        filter: invert(1);
      }
    }
  }
}
</style>
