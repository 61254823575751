<template>
	<button class="emphaziz" :dark="dark" :class="dark ? 'dark' : ''">
		<a :href="href">
			{{ text }}
			<div class="download-img" :href="href"></div>
		</a>
	</button>
</template>

<script>
export default {
	name: "MButton",
	props: {
		text: { type: String, required: true },
		href: { type: [String, Number], required: true },
		download: { type: Boolean, default: false },
		dark: { type: Boolean, default: false },
	},
	setup() {},
};
</script>

<style lang="scss" scoped>
.dark {
	&.dark {
		&.emphaziz {
			background: var(--darker);
			transition: all 0.3s ease-in-out;
			box-sizing: content-box;
			font-family: NouvelR;
			color: var(--lighter);
			&.privatedl {
				.download-img {
					background-color: transparent;
					background-image: url("../../assets/icon-lock-white.svg");
				}
			}
			.download-img {
				pointer-events: none;
				margin-left: 10px;
				background-image: url("../../assets/icon-download-white.svg");
				width: 24px;
				height: 24px;
				border-radius: 24px;
				background-size: 24px;
				background-position: center;

				box-sizing: border-box;
				border: 2px solid var(--lighter);
			}
			&:hover {
				.download-img {
					pointer-events: none;
					background-position-y: 22px;
					transition: all 0.8s ease-in-out;
				}
			}
		}
	}
}

body.dark-mode {
	button.send {
		.download-img {
			background-image: url("../../assets/icon-validate-black.svg");
		}
	}
	.dark {
		&.dark {
			&.emphaziz {
				&.privatedl {
					.download-img {
						background-image: url("../../assets/icon-lock-white.svg");
						border: 2px solid white;
						border-radius: 50%;
						filter: invert(1);
					}
				}
				.download-img {
					background-image: url("../../assets/icon-download-white.svg");
					border: 2px solid white;
					border-radius: 50%;
					filter: invert(1);
				}
			}
		}
	}
}
button {
	&.emphaziz {
		background-color: var(--lighter);
		.download-img {
			margin-left: 10px;
			background-image: url("../../assets/icon-download-black.svg");
			width: 24px;
			height: 24px;
			background-size: 24px;
			background-position: center;

			a {
				font-weight: 700;
			}
		}
	}
	&.send {
		background-color: var(--darker);
		margin: 32px 0;
		a {
			&:first-child {
				width: 100%;
			}
			justify-content: space-between;
		}
		.download-img {
			filter: invert(0);
			border-radius: 12px;

			background-image: url("../../assets/icon-validate-white.svg");
		}
	}
}

@media screen and (max-width: 940px) {
	.dark {
		button {
			&.emphaziz {
				background-color: var(--darker);
				a {
					color: var(--lighter);
				}
				.download-img {
					filter: invert(0);
				}
			}
		}
	}

	button {
		margin: 13px auto;
		width: 100%;
		&.emphaziz {
			.download-img {
				background-image: url("../../assets/icon-download-white.svg");
				border: 2px solid var(--lighter);
			}
		}
		&.send {
			.download-img {
				filter: invert(0);
			}
		}
	}
}
</style>