<template>
  <div class="dark-page">
    <div class="dark-block">
      <DarkMode />
      <ButtonGridMode />
    </div>
    <div class="lines">
      <div
        class="line"
        v-for="x in 5"
        :key="x"
        :style="'grid-column:' + x + '/' + x"
      ></div>
      <div class="content-two" style="grid-column: 2/6; grid-row: 1">
        <h2>terms of service</h2>
      </div>
      <div
        class="content"
        id="terms-of-service"
        style="grid-column: 2/6; grid-row: 2"
      ></div>
    </div>
    <a id="topreturn" @click="resetScroll" style="grid-column: 3/6; grid-row: 3"
      ><img src="../../assets/cta-top.svg" alt="cta top"
    /></a>
  </div>
</template>

<script>
import axios from "axios";
import DarkMode from "@/components/organisms/DarkMode.vue";
import ButtonGridMode from "@/components/organisms/ButtonGridMode.vue";
export default {
  components: {
    DarkMode,
    ButtonGridMode,
  },
  name: "TermsOfService",
  setup() {
    async function loadContent() {
      const result = await axios.get(
        `${process.env.VUE_APP_API}/pages/terms-of-service`,
        {
          headers: {
            Authorization: "Bearer B5p3PKmh86DJ79Y0SCa88OEpiUeYJZKcP4Ajt6vdKH9",
          },
        }
      );
      const el = document.getElementById("terms-of-service");
      el.innerHTML = result?.data?.data?.content;
    }
    loadContent();

    const body = document.getElementsByTagName("body")[0];
    function resetScroll() {
      body.scrollTo(0, 0);
    }

    return { resetScroll };
  },
};
</script>

<style lang="scss" scoped>
.dark-page {
  display: grid;
  flex-direction: column;
  padding: 0 130px;
  padding-right: 110px;
  padding-top: 100px;
  background-color: var(--lighter);
  color: var(--darker);
  width: 100%;
  word-break: normal;
  box-sizing: border-box;
  font-weight: 700;
  min-height: 100vh;
  @media screen and (max-width: 1200px) {
    padding: 64px 0;
    padding-top: 60px;
    padding-bottom: 0;
  }
  @media screen and (max-width: 1200px) {
    padding: 64px 0;
    padding-top: 60px;
    padding-bottom: 0;
  }
  .dark-block {
    position: fixed;
    top: 401px;
    margin-left: -90px;
    z-index: 5;
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
}

.word-break {
  word-break: break-all;
}
.lines {
  display: grid;
  grid-template-columns: repeat(5, 17.5fr);
  @media screen and (max-width: 1200px) {
    margin-left: 20px;
    margin-right: 25px;
    grid-template-columns: repeat(2, 50fr);
  }

  .line {
    border-right: 1px solid var(--grey-line);
    grid-row: 1/30;
    &:nth-child(1) {
      border-left: 1px solid var(--grey-line);
    }
    &:nth-child(3) {
      @media screen and (max-width: 1200px) {
        display: none;
      }
    }

    &:nth-child(4) {
      @media screen and (max-width: 1200px) {
        display: none;
      }
    }
    &:nth-child(5) {
      @media screen and (max-width: 1200px) {
        display: none;
      }
    }
  }
}
.content-two,
.content,
#topreturn {
  @media screen and (max-width: 1200px) {
    grid-column: 1/3 !important;
  }
}
</style>
