<template>
  <div class="landing">
    <img
        class="logo"
        src="../../assets/logo-renault-black.svg"
        alt="logo renault"
      />
    <img src="../../assets/maintenance.png">
    <div class="text">
      <h1>Under Construction</h1>
<p>Our website is under construction, we are working very hard to give you the best experience with this one.</p>
    </div>
  </div>
</template>

<script>
export default {
  setup () {
    

    return {}
  }
}
</script>
<style lang="scss" scoped>
.landing {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background-color: $lighter;
  word-break: normal;
  box-sizing: border-box;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1200px) {
    padding: 0 20px;
  }

  .logo {
    width: 100px;
    height: 100px;
  }

  img {
    width: 400px;
    height: 400px;
    @media screen and (max-width: 1200px) {
      width: 300px;
      height: 300px;
    }
  }
  .text {
    text-align: center;
  }
}
</style>