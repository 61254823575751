<template>
  <div id="banner" :class="bannerState">
    <h2 class="details">manage my cookies</h2>
    <p class="details">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa quo vero
      necessitatibus aspernatur sequi velit, nulla, quis laborum corporis
      perferendis ad sint, sit in quod quos blanditiis magnam similique! Eius.
      Autem temporibus ex atque qui? Quam aperiam impedit corporis harum
      quaerat officia commodi assumenda totam mollitia quis rerum
      reprehenderit veritatis fuga reiciendis voluptatum, praesentium ducimus
      similique labore eius laborum? Optio! Adipisci deleniti quod libero odit
      harum, nam illo nesciunt cumque beatae quia dolores soluta nihil eius,
      asperiores aliquam magni tenetur molestias officia nulla fugit quam
      explicabo! Enim temporibus nobis quos!
    </p>
    <div class="option">
      <input class="checkbox" type="checkbox" v-model="cookies[0]" />
      <div class="descritpion">
        <h3>Lorem ipsum dolor sit amet</h3>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Delectus
          incidunt magnam optio vero quam aliquid odit temporibus ullam dicta
          voluptatum. Quia iusto quibusdam repellat dolore provident commodi,
          pariatur id dolor.
        </p>
      </div>
    </div>
    <div class="option">
      <input class="checkbox" type="checkbox" v-model="cookies[1]" />
      <div class="descritpion">
        <h3>Lorem ipsum dolor sit amet</h3>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Delectus
          incidunt magnam optio vero quam aliquid odit temporibus ullam dicta
          voluptatum. Quia iusto quibusdam repellat dolore provident commodi,
          pariatur id dolor.
        </p>
      </div>
    </div>
    <div class="option">
      <input class="checkbox" type="checkbox" v-model="cookies[2]" />
      <div class="descritpion">
        <h3>Lorem ipsum dolor sit amet</h3>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Delectus
          incidunt magnam optio vero quam aliquid odit temporibus ullam dicta
          voluptatum. Quia iusto quibusdam repellat dolore provident commodi,
          pariatur id dolor.
        </p>
      </div>
    </div>
    <span class="left onclosed" >for renault, respecting your privacy is a priority
    </span>
    <div class="right">
      <button @click="handleAcceptAll">
        accept all
        <img
          src="../../assets/icon-validate-white.svg"
          alt="icon download black"
        />
      </button>
      <button @click="handleRefuseAll">
        refuse all
        <img
          src="../../assets/icon-refuse-white.svg"
          alt="icon download black"
        />
      </button>
      <a href="#banner" @click="bannerState='opened'" class="onclosed"
        >customize choices</a
      >
      <button @click="validate" class="details">
        validate my choices
        <img
          src="../../assets/icon-validate-white.svg"
          alt="icon download black"
        />
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";

export default {
  name: "CookieBanner",
  setup(props, { emit }) {
    const bannerState = ref('closed');
    const cookies = ref([true, true, true]);

    function validate() {
      bannerState.value = 'opened';
      emit("validate");
    }
    function handleAcceptAll() {
      cookies.value = [true, true, true];
      if (bannerState.value == 'closed') validate();
    }
    function handleRefuseAll() {
      cookies.value = [false, false, false];
      if (bannerState.value == 'closed') validate();
    }

    return {
      bannerState,
      cookies,
      handleAcceptAll,
      handleRefuseAll,
      validate,
    };
  },
};
</script>

<style scoped lang="scss">
#banner {
  position: fixed;
  bottom: 0;
  display: none;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  width: 100%;
  box-sizing: border-box;
  padding: 32px 128px;
  font-size: 18px;
  z-index: 99;
  transition: all 1s ease-in-out;
  &.opened{
    flex-direction: column;
    align-items: flex-start;
    .onclosed{
      display: none;
    }
    .right{
      align-self: flex-end;
      margin-top: 32px;
      button{
        margin-left: 0;
      }
    }
  }
  &.closed {
    flex-direction: row;
    .option, .details{
      display: none;
    }
  }
  #customize{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;
  }
  .option {
    display: flex;
    align-items: center;
  }
  button,
  a {
    white-space: nowrap;
  }
  #default {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .left {
    display: flex;
    align-items: center;
  }
  .right {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 1200px) {
  #banner {
    padding: 32px;
    #default {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      .left {
        margin-bottom: 32px;
      }
      .right {
        align-items: center;
        justify-content: flex-start;
        width: 100%;
      }
      button {
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
@media screen and (max-width: 620px) {
  #banner {
    #default {
      align-items: flex-start;
      .right {
        flex-direction: column;
        align-items: flex-start;
        button {
          margin: 16px 0;
          width: 100%;
        }
        a {
          margin: 16px 0 0 0;
        }
      }
    }
  }
}
@media screen and (max-width: 700px) {
  #banner{
    &.closed{
      flex-direction: column;
      .right{
        flex-direction: column;
        width: 100%;
        button, a{
          width: 100%;
          margin: 32px 0 0 0;
          text-align: center;
        }
      }
    }
    &.opened{
      position: relative;
      top: 0;
      z-index: 32;
      .right{
        flex-direction: column;
        align-self: flex-start;
        button{
          width: 100%;
          margin: 16px 0;
        }
      }
    }
  }
}
</style>
