<template>
  <button
    @click="gridMode"
    class="grid-button"
    :class="{ 'is-active': value == 'on', 'is-invert': invert }"
  >
    <span> grid:</span
    ><span class="active">{{ value == "on" ? "on" : "off" }}</span>
  </button>
</template>

<script>
import { useToggle } from "@/hooks/useToggle";
import { ref, onMounted, watch } from "@vue/runtime-core";
export default {
  props: {
    invert: { type: Boolean, required: false, default: false },
  },
  setup() {
    const { state: show, toggle: showGridMode } = useToggle();
      const value = ref(localStorage.getItem("grid"));
    watch(value, (newX) => {
          const allGrid = document.querySelectorAll('.grid-button');
          allGrid.forEach(grid => {
              if (newX === 'on') {
                  grid.classList.add('is-active')
                  grid.children[1].innerHTML = 'on'
              }
              if (newX === 'off') {
                  grid.classList.remove('is-active')
                  grid.children[1].innerHTML = 'off'
              }
          })
      });
    onMounted(() => {
      const body = document.getElementsByTagName("body")[0];
      if (value.value === "off") {
        localStorage.setItem("onMounted", "off");
        value.value = "off";
        body.classList.remove("grid-mode");
      }
      if (value.value === "on") {
        localStorage.setItem("grid", "on");
        value.value = "on";
        body.classList.add("grid-mode");
      }
    });
    const gridMode = () => {
      const body = document.getElementsByTagName("body")[0];
      if (body.classList.contains("grid-mode")) {
        value.value = "off";
        localStorage.setItem("grid", "off");
        body.classList.remove("grid-mode");
      } else {
        value.value = "on";
        localStorage.setItem("grid", "on");
        body.classList.add("grid-mode");
      }
    };

    return { show, gridMode, showGridMode, value };
  },
};
</script>

<style lang="scss" scoped>
.grid-button {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  padding: 0%;
  text-align: center;
  display: block;
  margin: 0;
    @media screen and (min-width: 1200px) {
  &:hover {
    background: #FFF26E;
    color: #000;
  }
  }

  &.is-invert {
    filter: invert(1);
    &.is-active {
      filter: invert(0);
      background: $yellow2;
      color: #000;
    }
  }
  &.is-active {
    background: $yellow2;
    color: #000;
  }
  span {
    font-size: 12px;
    line-height: 12px;
    padding: 0;
    font-weight: normal;
  }
  .active {
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
  }
}
body.dark-mode {
    .grid-button {
         &.is-active {
         @media screen and (min-width: 1200px) {
          &:hover {
           background: #FFF;
          }
          }
         }
    }
}

body.grid-mode {
    .grid-button {
         &.is-active {
         @media screen and (min-width: 1200px) {
          &:hover {
           background: #000;
           color: #FFF;
          }
          }
         }
    }
}
body.grid-mode.dark-mode {
    .grid-button {
         &.is-active {
         @media screen and (min-width: 1200px) {
          &:hover {
          background: #FFF;
          color: #000;
          }
          }
         }
    }
}
</style>
