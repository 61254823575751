<template>
	<Header v-if="$route.path !== '/maintenance'"></Header>
	<notif-pop-up @showModal="showModal"></notif-pop-up>
	<GridMode v-if="$route.path !== '/brand-platform' && $route.path !== '/' && $route.path !== '/maintenance' && $route.path !== '/legal-notice' && $route.path !== '/terms-of-service' && $route.path !== '/personal-data' && $route.path !== '/cookie-policy' && $route.path.substring(1).split('/')[0] !== 'fundamental-guidelines'" />

	<router-view />

	<CookieBanner @validate="showCookieBanner = false" v-show="showCookieBanner"></CookieBanner>
	<Footer @manageCookies="showCookieBanner = true" v-if="$route.path !== '/maintenance'"></Footer>
</template>

<script>
import store from "@/store";
import Header from "@/components/organisms/Header";
import GridMode from "@/components/organisms/GridMode";
import Footer from "@/components/organisms/Footer";
import CookieBanner from "@/components/organisms/CookieBanner";
import isMobile from "is-mobile";
import { ref } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import { computed, provide } from "@vue/runtime-core";
import { useNavigation } from "@/hooks/useNavigation";
import NotifPopUp from "@/components/organisms/NotifPopUp.vue";
import axios from "axios";
export default {
	name: "App",
	components: { Header, Footer, CookieBanner, GridMode, NotifPopUp },
	setup() {
		const navigation = useNavigation();
		provide("navigation", navigation);
		provide("store", store);

		const isMobileDevice = isMobile();
		const showCookieBanner = ref(true);
		const playing = ref(false);
		const overlayClassList = ref("overlay");
		const classPlay = ref("");
		const route = useRoute();
		const showVideo = computed(() => route.name === "Landing");

		const content = ref();

		async function loadContent() {
			const result = await axios.get(`${process.env.VUE_APP_API}/downloads`, {
				headers: {
					Authorization: "Bearer B5p3PKmh86DJ79Y0SCa88OEpiUeYJZKcP4Ajt6vdKH9",
				},
			});
			content.value = result?.data?.data;
		}

		loadContent();

		const filterProducts = computed(() => {
			return content.value?.filter((item) => item.tag !== "");
		});

		const showTest = ref(false);
		function showModal(val) {
			showTest.value = val;
		}

		function toggle() {
			const video = document.getElementById("myVideo");
			if (!playing.value) {
				video.play();
				playing.value = true;
				overlayClassList.value = `overlay hidden ${classPlay.value}`;
			} else {
				video.pause();
				playing.value = false;
				overlayClassList.value = `overlay ${classPlay.value}`;
			}
		}

		const body = document.getElementsByTagName("body")[0];

		body.addEventListener("scroll", backTop);
		function backTop() {
			const backToTop = document.getElementById("topreturn");
			if (!backToTop) return;
			let scrollY = body.scrollTop;

			if (scrollY > 2400) backToTop.classList.add("return-sticky");
			else backToTop.classList.remove("return-sticky");
		}

		const { dataLayer } = window;
		dataLayer.push({
			brand: "renault",
			businessType: "internal",
			countryCode: "FR",
			googleAccount: "UA-10298853-10",
			languageCode: "fr",
			pageName: "homepage",
			pageType: "homepage",
		});

		return {
			isMobileDevice,
			showCookieBanner,
			overlayClassList,
			toggle,
			showVideo,
			classPlay,
			content,
			filterProducts,
			showTest,
			showModal,
		};
	},
};
</script>

<style lang="scss">
//Vars

@keyframes smooth {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

html {
	font-family: NouvelR;
	font-style: normal;
	position: relative;
	overflow: hidden;
	height: 100%;
	.ccb-sticky-label {
		padding: initial;
		display: unset;
	}
	span {
		padding: 32px;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		&.text {
			display: flex;
			box-sizing: border-box;
			width: 100%;
			p {
				display: flex;
				flex-direction: column;
				box-sizing: border-box;
				width: 100%;
				margin-block-start: 0;
				margin-block-end: 0;
				span {
					box-sizing: border-box;
					padding: 0;
					width: 100%;
					display: inline;
				}
			}
		}
		b {
			box-sizing: border-box;
		}
	}
	body {
		margin: 0 auto;
		background-image: url("./assets/logo-renault-white.svg");
		background-position: center;
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-size: 20%;
		background-color: var(--darker);
		height: 100%;
		overflow: auto;
		#video {
			position: relative;
			z-index: -1;
			height: 100vh;
			video {
				position: fixed;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				width: 100%;
				height: 100vh;
				z-index: 0;
				object-fit: cover;
				transition: all 1s ease-in-out;
				animation-name: smooth;
				animation-duration: 3s;
				animation-timing-function: ease-in-out;
			}
		}
	}
}
.overlay {
	display: none;
}
.sub {
	display: flex;
	flex-flow: wrap;
}

@media screen and (max-width: 525px) {
	html {
		z-index: 0;
		body {
			max-height: 100vh;
			#video {
				display: flex;
				background-color: var(--darker);
				position: unset;
				width: 100%;
				height: 100vh;
				animation-name: smooth;
				animation-duration: 3s;
				animation-timing-function: ease-in-out;
				video {
					position: absolute;
					z-index: 0;
					top: 0;
					width: 100%;
					left: 0;
					transform: translate(0, 0);
					object-fit: contain;
					transition: all 1s ease-in-out;
				}
				.overlay {
					display: block;
					z-index: 0;
					width: 100%;
					height: 100vh;
					background-color: rgba($color: var(--darker), $alpha: 0.2);
					background-image: url("./assets/logo-renault-white.svg");
					background-repeat: no-repeat;
					background-position: center;
					background-size: 50%;
					background-blend-mode: multiply;
					transition: all 1s ease-in-out;
					animation-name: smooth;
					animation-duration: 3s;
					animation-timing-function: ease-in-out;
					&.hidden {
						opacity: 0;
					}
					&.play {
						background-image: none;
					}
					button {
						&.play {
							position: relative;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							width: 60px;
							height: 60px;
							background-color: var(--lighter);
							margin: 0;
							border-radius: 0;
							z-index: 2;
							&::after {
								content: "";
								display: inline-block;
								height: 0;
								width: 0;
								border-top: 8px solid transparent;
								border-bottom: 8px solid transparent;
								border-left: 14px solid var(--darker);
								transform: translateX(60%);
							}
						}
					}
				}
			}
		}
	}
}
section {
	display: none;
}

@media screen and (max-width: 960px) {
	html {
		body {
			background-size: 50%;
		}
	}
	.slidefromside {
		line-height: initial !important;
		font-size: 35px !important;
		margin-top: initial;
		&:first-child {
			margin-top: initial;
		}
	}
	#title-0 {
		font-size: 24px !important;
		line-height: 74px;
	}
	#title-1 {
		font-size: 45px !important;
		line-height: 0 !important;
	}
	h1 {
		.word {
			display: flex;
			margin-right: 9px !important;
		}
	}
}
h1 {
	.word {
		display: flex;
		margin-right: 30px;
		position: relative;
		z-index: 0;
		white-space: pre;
		&::after {
			content: "";
			position: absolute;
			display: block;
			background-color: transparent;
			height: 1em;
			width: 100%;
			top: 0.92em;
		}
		.char {
			opacity: 0;
			animation-name: slidein;
			animation-duration: 1s;
			animation-fill-mode: forwards;
		}
	}
}

.impair {
	transform: translate(-50vh, 0);
}
.pair {
	transform: translate(50vh, 0);
}
.slidefromside {
	line-height: 97px;
	font-size: 100px;
	margin-top: 70px;
	&:nth-child(1) {
		padding-top: 15px;
	}
}

#title-0 {
	font-size: 36px;
}
#title-1 {
	font-size: 100px;
	line-height: 40px;
}

.pair,
.impair {
	transition: all 1s ease-in-out;
	opacity: 0;
	&.reset {
		transform: translate(0, 0);
		opacity: 1;
	}
}
.description {
	span {
		padding-left: 0;
		padding-bottom: 0;
	}
}
@keyframes slidein {
	0% {
		opacity: 1;
		transform: rotate(10deg);
		margin-top: 1em;
		opacity: 0;
	}

	25% {
		opacity: 1;
		transform: rotate(20deg);
	}

	50% {
		margin-top: -0.1em;
	}

	100% {
		opacity: 1;
		transform: rotate(0deg);
		margin: 0;
	}
}

@keyframes taadaa {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}
}
@keyframes transformtest {
	0% {
		transform: translate3d(0px, 100%, 0px) scale3d(1, 1, 1);
	}

	50% {
		transform: translate3d(0px, 50%, 0px) scale3d(1, 1, 1);
	}
	75% {
		transform: translate3d(0px, 25%, 0px) scale3d(1, 1, 1);
	}
	100% {
		transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1);
	}
}
@keyframes bounce {
	0% {
		opacity: 1;
		transform: translateY(0px) scale(1);
	}
	25% {
		opacity: 0;
		transform: translateY(10px) scale(0.9);
	}
	26% {
		opacity: 0;
		transform: translateY(-10px) scale(0.9);
	}
	55% {
		opacity: 1;
		transform: translateY(0px) scale(1);
	}
}

@keyframes testAnim {
	0% {
		transform: translate3d(0, 0, 0);
		z-index: 1;
	}

	50% {
		transform: translate3d(0, -100%, 0);
		background: white;
		z-index: 1;
		opacity: 0.9;
	}

	100% {
		transform: translate3d(0px, -150%, 0px);
		background: transparent;
		opacity: 0.1;
	}
}
</style>
