<template>
  <div class="auth-pop-in" :class="{ hide: !isPopInVisible }">
    <div v-show="success" class="snackbar">your email has been sent</div>
    <span @click="hidePopIn" class="close"> <img src='../../assets/cta-close-black.svg'></span>
    <div class="content">
      <h2>sign in</h2>
      <h3>only renault and agencies employees are allowed</h3>
      <p>
        The personal information we collect is collected through forms and
        through the interactivity established between you and our website. We
        also use, as described in the following section, cookies and / or logs
        to collect information about you.
      </p>
      <a @click="hidePopIn" class="return">return to previous page</a>
      <input type="text" name="email" id="email" placeholder="email" v-model="email" class="email"/>
      <span v-show="error" class="error">Email unauthorized</span>
      <MButton text="send" @click="send" class="send"></MButton>
    </div>
  </div>
</template>

<script>
import MButton from "@/components/molecules/MButton";
import axios from "axios";
import { ref } from "@vue/reactivity";

export default {
  name: "AuthPopIn",
  components: { MButton },
  props: ["isPopInVisible", "fileNumber"],
  setup(props, { emit }) {
    const email = ref("");
    const error = ref(false);
    const success = ref(false);

    function hidePopIn() {
      emit("hide-pop-in");
      error.value=false
      success.value = false
    }

    async function send() {
      axios
        .post(
          `${process.env.VUE_APP_API}/file-requests`,
          {
            mail: email.value,
            file: props.fileNumber,
          },
          {
            headers: {
              Authorization:
                "Bearer B5p3PKmh86DJ79Y0SCa88OEpiUeYJZKcP4Ajt6vdKH9",
            },
          }
        )
        .then(({ status }) => {
          success.value = status === 201
          error.value = false
          document.cookie ="file_" + props.fileNumber +'= true';
          email.value = ""
        })
        .catch(() => {
          success.value = false
          error.value = true
        })
        .finally(() => {
          setInterval(() => {
            success.value = false

          }, 3000)
        });
    }

    return { hidePopIn, email, send, success, error };
  },
};
</script>

<style lang="scss" scoped>

body.dark-mode {
  .close{
    filter: invert(1);
  }
  .send {
      .download-img {
      filter: invert(0);
    }
  }
}
.auth-pop-in {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  background-color: var(--lighter);
  z-index: 64;
  height: 100vh;
  width: 100vw;
  padding: 32px;
  box-sizing: border-box;
  &.hide {
    display: none;
  }
  .close {
    position: absolute;
    cursor: pointer;
    top: 70px;
    right: 130px;
    padding: 0;
    img {
      width: 35px;
      height: 35px;
    }
  }
  .send {
    margin-top: 40px;
  }
  .email {
    padding-top: 40px;
  }
  .content {
    width: 100%;
    max-width: 1000px;
    box-sizing: border-box;
    h2 {
      font-size: 100px;
      margin: 0;
    }
    h3 {
      font-size: 36px;
      padding-top: 19px;
      margin-block-start: 0em;
      margin-block-end: 0em;
    }
    p {
      font-size: 21px;
       margin-block-start: 0em;
       margin-block-end: 0em;
       padding-top: 20px;
       padding-bottom: 40px;
    }
    a {
      margin: 0;
    }
    .error{
      display: inline-flex;
      position: relative;
      background-color: var(--yellow);
      margin-top: 16px;
      font-size: 16px;
      font-weight: 700;
      padding: 2px;
    }
  }
}
@media screen and (max-width: 500px) {
  .auth-pop-in {
    .close {
      top: 32px;
      right: 32px;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
      }
    }
    .content {
      h2 {
        font-size: 50px;
      }
      h3 {
        font-size: 24px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  .send {
      .download-img {
      filter: invert(0);
    }
  }
}
</style>
