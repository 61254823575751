import { useAxios } from '@/hooks/useAxios'
import { computed } from '@vue/runtime-core';
import { useRoute } from 'vue-router';

export const useNavigation = () => {
    const { data } = useAxios('brands')
    const route = useRoute()

    const brands = computed(() =>
      data.value.map(({ id, status, slug, title, categories }) => ({
        id,
        status,
        slug,
        title,
        categories,
      }))
    );
    const selectedBrand = computed(() =>
      route?.params?.brand
        ? brands.value.filter((b) => b.slug === route.params.brand)[0]
        : brands.value[0]
    );
    const selectedCategory = computed(() =>
      route?.params?.category
        ? selectedBrand?.value?.categories?.filter(
            (c) => c.slug === route.params.category
          )[0]
        : selectedBrand?.value?.categories[0]
    );
    const categories = computed(() => selectedBrand?.value?.categories);
    
    const selectedContent = computed(() => 
      route?.hash?.substring(1) 
        ? selectedCategory?.value?.contents.filter((c) => c.slug === route.hash.substring(1))[0]
        : selectedCategory?.value?.contents[0]
    );


    return { data, brands, selectedBrand, selectedCategory, categories, selectedContent }
}
