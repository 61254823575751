<template>
  <div class="container">
    <input
      type="range"
      min="200"
      max="700"
      class="slider"
      v-model="value"
      @input="calculated(value)"
    />
    <span :style="{ 'font-weight': fontWeight }"> renault </span>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  name: "TypoVariation",

  setup() {
    let fontWeight = ref();
    function calculated(ev) {
      fontWeight.value = ev;
    }
    return { fontWeight, calculated };
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 32px;
  box-sizing: border-box;
  padding-bottom: 0;
  span {
    font-size: 16vw;
    padding: 32px;
    box-sizing: border-box;
    flex-grow: 0;
    margin: 64px 0;
  }
  input[type="range"] {
    height: 31px;
    -webkit-appearance: none;
    flex-grow: 1;
    box-sizing: border-box;
    margin: 64px 0;
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px var(--darker);
    background: var(--darker);
    border-radius: 1px;
    border: 0px solid var(--darker);
  }
  input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px var(--darker);
    border: 1px solid var(--darker);
    height: 24px;
    width: 24px;
    border-radius: 23px;
    background: var(--darker);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -11.5px;
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: var(--darker);
  }
  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px var(--darker);
    background: var(--darker);
    border-radius: 1px;
    border: 0px solid var(--darker);
  }
  input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 0px var(--darker);
    border: 1px solid var(--darker);
    height: 24px;
    width: 24px;
    border-radius: 23px;
    background: var(--darker);
    cursor: pointer;
  }
  input[type="range"]::-ms-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type="range"]::-ms-fill-lower {
    background: var(--darker);
    border: 0px solid var(--darker);
    border-radius: 2px;
    box-shadow: 0px 0px 0px var(--darker);
  }
  input[type="range"]::-ms-fill-upper {
    background: var(--darker);
    border: 0px solid var(--darker);
    border-radius: 2px;
    box-shadow: 0px 0px 0px var(--darker);
  }
  input[type="range"]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px var(--darker);
    border: 1px solid var(--darker);
    height: 24px;
    width: 24px;
    border-radius: 23px;
    background: var(--darker);
    cursor: pointer;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: var(--darker);
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: var(--darker);
  }
}
@media screen and (max-width: 1500px) {
  .container {
    span {
      font-size: 12vw;
    }
  }
}
</style>
