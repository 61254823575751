<template>
  <TComponents></TComponents>
</template>

<script>
import TComponents from '@/components/templates/TComponents'

export default {
  name: 'PComponents',
  components: { TComponents }
}
</script>
