
<template>
  <a :href="href" class="link" v-if="href">discover</a>
</template>

<script>
export default {
  name: "ExternalLink",
  props: { href: { type: [String], required: true } },
  setup() {},
};
</script>

<style lang="scss" scoped>
body.dark-mode {
  .link::after {
    filter: invert(1);
  }
}
.link {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  padding: 0;
  margin: 0;
  padding-top: 30px;
  line-height: initial;
  @media screen and (max-width: 1200px) {
    padding-top: 15px;
  }
  &::after {
    content: "";
    margin-left: 10px;
    background-image: url("../../assets/icon-cta-arrow-black.svg");
    background-size: 10px;
    background-position: center;
    overflow: hidden;
    width: 10px;
    height: 10px;
  }
  &:hover {

    &::after {
     animation: bounce 0.6s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    }
  }
}

</style>
