<template>
	<div class="filter-container" v-if="content">
		<div class="filter-mobile" v-if="mobile" :class="{ open: showFilterMobile }">
			<div class="filter-by" @click="handleToggleNav" :class="{ open: showFilterMobile }">
				<span>filter by</span>
			</div>

			<div v-show="showFilterMobile">
				<div class="categories-container">
					<div class="brand-container">
						<div class="filter-brand" @click="showBrand">
							<span>brands</span>
						</div>
						<div class="list-mobile" v-if="brandList">
							<div @click="filterByBrand(null)" :class="{ active: brandSelected == null }">
								all
							</div>
							<div v-for="(brand, index) in selectBrand" :key="index" @click="filterByBrand(brand)" :class="{ active: brandSelected == brand }">
								{{ brand }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div style="display: flex; flex: 5" v-if="!mobile">
			<div v-if="!mobile" style="flex:1" class="removelist" @click="clickOutside"></div>
			<div class="brand-container" v-if="!mobile" style="flex:1">
				<div class="brand-list" :class="{ active: brandList }">
					<span @click="showBrand">
						filter by brand
						<img src="../../assets/arrow-filter-black.svg" />
					</span>
					<template v-if="brandList">
						<div @click="filterByBrand(null)" :class="{ active: brandSelected == null }">
							all
						</div>
						<div v-for="(brand, index) in selectBrand" :key="index" @click="filterByBrand(brand)" :class="{ active: brandSelected == brand }">
							{{ brand }}
						</div>
					</template>
				</div>
			</div>
			<div v-if="!mobile" style="flex:3" class="removelist" @click="clickOutside"></div>
		</div>
		<div v-if="!mobile" style="flex:1" class="removelist" @click="clickOutside"></div>
	</div>
</template>

<script>
import { ref, computed, onUpdated, onMounted } from "@vue/runtime-core";
import axios from "axios";
import isMobile from "is-mobile";
import { useToggle } from "@/hooks/useToggle";
export default {
	props: ["show"],
	setup(props, { emit }) {
		const content = ref();

		async function loadContent() {
			const result = await axios.get(`${process.env.VUE_APP_API}/downloads`, {
				headers: {
					Authorization: "Bearer B5p3PKmh86DJ79Y0SCa88OEpiUeYJZKcP4Ajt6vdKH9",
				},
			});
			content.value = Object.values(result?.data?.data);
		}
		const selectBrand = computed(() => [...new Set(content.value.map((item) => item.brand))]);

		loadContent();

		onUpdated(() => {
			if (props.show === false) {
				brandList.value = false;
			}
		});

		onMounted(() => {
			showFilterMobile.value = false;
		});

		// filter Brand
		const brandList = ref(false);
		function showBrand() {
			brandList.value = !brandList.value;
			emit("brandList", brandList.value);
		}
		const brandSelected = ref(null);
		function filterByBrand(brand) {
			brandSelected.value = brand;
			if (brandSelected !== null) brandList.value = false;
			showFilterMobile.value = false;

			emit("brandSelected", brandSelected.value);
		}

		const { state: showFilterMobile, toggle: ToogleMobile } = useToggle();

		const handleToggleNav = () => {
			brandList.value = true;
			ToogleMobile();
		};

		function clickOutside() {
			brandList.value = false;
		}

		return {
			content,
			selectBrand,
			brandSelected,
			filterByBrand,
			showBrand,
			brandList,
			mobile: isMobile(),
			showFilterMobile,
			ToogleMobile,
			handleToggleNav,
			clickOutside,
		};
	},
};
</script>

<style lang="scss" scoped>
.filter-container {
	display: flex;
	padding: 20px 130px;
	border-bottom: 2px solid var(--darker);
	position: sticky;
	top: 100px;
	background: var(--lighter);
	z-index: 2;
	@media screen and (max-width: 1200px) {
		padding: 20px 0;
		top: 61px;
	}

	& > div {
		flex: 1;
	}
	.mobile {
		@media screen and (max-width: 1200px) {
			display: none;
		}
	}
}

.categories-container,
.brand-container {
	position: relative;
	span {
		font-weight: 700;
		padding: 0;
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		cursor: pointer;
		img {
			width: 12px;
			height: 5px;
			margin-left: 10px;
			transform: rotate(180deg);
		}
	}
	.categories-list,
	.brand-list {
		display: block;
		position: absolute;
		padding: 20px 0 32px;
		top: -20px;
		&.active {
			background: var(--lighter);
			border: 1px solid;
			border-top: 0;
			width: 540px;
			z-index: 2;
			img {
				transform: rotate(0deg);
			}
		}
		& > div {
			cursor: pointer;
			margin: 0 155px;
			margin-top: 10px;
			&:first-child {
				margin-top: 0;
			}
		}
		.active {
			position: relative;
			font-weight: 700;
			&::before {
				position: absolute;
				content: "";
				left: -32px;
				width: 16px;
				height: 23px;
				background-image: url("../../assets/icon-select.svg");
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
			}
		}
		span {
			padding-bottom: 20px;
			display: flex;
			flex-direction: row;
			align-items: center;
			padding-top: 5px;
			img {
				width: 12px;
				height: 5px;
				margin-left: 10px;
			}
		}
	}
}
.categories-list {
	left: -111px;
	span {
		margin-left: 111px;
	}
	&.active {
		span {
			margin-left: 110px;
		}
	}
}
.brand-list {
	left: -125px;

	span {
		margin-left: 125px;
	}
	&.active {
		span {
			margin-left: 124px;
		}
	}
}
body.dark-mode {
	.filter-by {
		&::after {
			filter: invert(1);
		}
	}
}
.filter-mobile {
	display: flex;
	flex-direction: column;
	&.open {
		height: 100vh;
	}
	.filter-by {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		padding-left: 20px;
		&.open {
			padding: 20px;
			padding-top: 0;
			border-bottom: 2px solid var(--darker);
			&::after {
				bottom: 20px;
				content: "";
				transform: rotate(0);
			}
		}
		&::after {
			position: absolute;
			right: 20px;
			bottom: 0;
			content: "";
			width: 16px;
			height: 16px;
			background-image: url("../../assets/arrow-filter-black.svg");
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			transform: rotate(180deg);
		}
	}
	span {
		padding: 0;
		font-weight: 700;
	}
	img {
		width: 12px;
		height: 5px;
		margin-right: 12px;
	}
}

.filter-brand {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid var(--darker);
	padding: 20px;
	position: relative;
	&::after,
	&::before {
		content: "";
		position: absolute;
		right: 20px;
		width: 16px;
		height: 16px;
		background-image: url("../../assets/arrow-filter-black.svg");
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}
	&::after {
		bottom: 30px;
	}
	&::before {
		top: 30px;
		transform: rotate(180deg);
	}
}

.list-mobile {
	display: flex;
	flex-direction: column;
	padding-left: 62px;
	padding-top: 30px;
	& > div {
		margin-top: 10px;
		font-size: 16px;
		line-height: 26px;
	}
	& > div:first-child {
		margin-top: 0;
	}
	.active {
		position: relative;
		font-weight: 700;
		&::before {
			position: absolute;
			content: "";
			left: -32px;
			width: 16px;
			height: 23px;
			background-image: url("../../assets/icon-select.svg");
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}
	}
}

body.dark-mode {
	.categories-container .categories-list,
	.brand-container .brand-list {
		span img {
			filter: invert(1);
		}
		.active::before {
			filter: invert(1);
		}
	}
}
.removelist {
	margin: -20px 0;
	height: 70px;
	position: relative;
	&:first-child {
		&::before {
			content: "";
			display: block;
			height: 70px;
			width: 130px;
			margin-left: -130px;
			position: absolute;
		}
	}
	&:last-child {
		&::after {
			content: "";
			display: block;
			width: 130px;
			margin-top: -1px;
			position: absolute;
			height: 70px;
			right: 0;
			margin-right: -130px;
		}
	}
}
</style>