<template>
<div class="lines">
   <div
        class="line-grid"
        v-for="x in 5"
        :key="x"
        :style="'grid-column:' + x + '/' + x"
      ></div>
</div>
</template>

<script>
export default {
  setup () {
    

    return {}
  }
}
</script>

<style lang="scss" scoped>

.lines {
    display: grid;
    grid-template-columns: repeat(5, 17.5fr);
    margin: 0;
    max-width: calc(100% - 240px);
    width: 100%;
    height: 100%;
    position: fixed;
    margin-left: 130px;
    margin-right: -130px;
    pointer-events: none;
    @media screen and (max-width: 1200px) {
      max-width: calc(100% - 40px);
      margin-left: 20px;
      margin-right: 25px;
      grid-template-columns: repeat(2, 50fr);
    }

    .line-grid {
      height: 100%;
      pointer-events: none;
      -webkit-user-select: none;
      user-select: none;
      border-right: 1px solid var(--grey-line);
      &:nth-child(1) {
        border-left: 1px solid var(--grey-line);
      }
      &:nth-child(3) {
        @media screen and (max-width: 1200px) {
          display: none;
        }
      }

      &:nth-child(4) {
        @media screen and (max-width: 1200px) {
          display: none;
        }
      }
      &:nth-child(5) {
        @media screen and (max-width: 1200px) {
          display: none;
        }
      }
    }
  }
</style>
