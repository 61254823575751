<template>
  <div class="container-image">
    <div v-for="(media, key) in items" :key="key" class="images">
      <img :src="media">
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
import isMobile from "is-mobile";
export default {
  props: { media: Object, ctype: { required: true, type: Number } },
  setup(props) {
    const current = ref(0);
    const isMobileDevice = isMobile();
    const items = computed(() =>
      props.media ? Object.values(props.media).filter((v) => v.length) : []
    );

    

    return {current, items, isMobileDevice}
  }
}
</script>

<style lang="scss" scoped>
.container-image {
  display: flex;
  padding: 0 32px;
  flex-direction: row;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
  .images {
    display: flex;
    flex: 1;
    margin-right: 20px;
    z-index: 2;
    @media screen and (max-width: 1200px) {
      margin-right: 0;
      &:first-child {
        margin-top: 0;
      }
      margin-top: 20px;
    }
    &:last-child {
      margin-right: 0;
    }
    img {
      max-width: 100%;
    }
  }
}

</style>