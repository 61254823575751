<template>
  <section class="notfound">
    <div class="center">
      <h2>well, that was kind of unexpected</h2>
      <router-link to="/">go to home page</router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "PageNotFound"
};
</script>

<style lang="scss" scoped>
.notfound {
  height: 100vh;
  width: 100%;
  min-width: 100%;
  max-width: 1000px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--darker);
  color: var(--lighter);
  .center {
    width: fit-content;
    padding: 64px;
    box-sizing: border-box;
    h2 {
      margin: 0 auto;
      font-weight: 700;
      font-size: 4em;
      @media screen and (max-width: 500px) {
        font-size: 2em;
      }
    }
    a {
      margin: 0;
      color: var(--lighter);
    }
  }
}
</style>
