<template>
  <footer>
    <div id="branding">
      <router-link to="/" exact>
        <img src="../../assets/logo-renault-white.svg" alt="logo-renault-black" />
      </router-link>
      <small>© 2021 renault</small>
    </div>
    <nav>
      <div id="top-line">
        <span class="softlinks">
          <a href="/brand-platform" class="active">brand platform</a>
          <a href="/fundamental-guidelines" class="active">fundamental guidelines</a>
          <a href="/showcase" class="active">showcase</a>
          <a href="/downloads" class="active">downloads</a>
        </span>
        <span>
          <a class="hide" href="https://www.renaultgroup.com/"
            >visit renault group<img src="../../assets/arrow.svg" alt="arrow"
          /></a>
        </span>
        <span>
          <a class="hide" href="https://www.instagram.com/renault_france/">
            <img
              src="../../assets/icon-instagram-white.svg"
              alt="icon-svg-white"
              id="instagram"
            />
          </a>
        </span>
      </div>
      <div id="bottom-line">
        <router-link to="/legal-notice" exact>legal notice</router-link>
        <router-link to="/terms-of-service" exact>terms of service</router-link>
        <router-link to="/cookie-policy" exact>cookie policy</router-link>
        <router-link to="/personal-data" exact>personal data</router-link>
        <!-- <a @click="manageCookies">manage your cookies</a> -->
      </div>
    </nav>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  setup(props, { emit }) {
    function manageCookies() {
      emit("manageCookies");
    }
    return { manageCookies };
  },
};
</script>

<style lang="scss">

footer {
  display: flex;
  position: sticky;
  align-items: stretch;
  justify-content: center;
  background-color: #000;
  color: #FFF;
  padding: 32px 64px;
  box-sizing: border-box;
  border-top: 1px solid var(--darker);
  a {
    margin: 0;
  }
  #branding {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(1 / 5 * 100%);
    margin-left: 64px;
    font-weight: 200;
    img {
      height: 65px;
      margin-bottom: 32px;
      cursor: pointer;
    }
    small {
      font-size: 16px;
    }
  }
  nav {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .softlinks{
      padding: 0;
    }
    .softlinks > a {
      color: var(--grey);
      text-decoration: none;
      cursor: default;
      &.active{
         color: #FFF;
         cursor: pointer;
      }
    }
    #top-line {
      display: flex;
      flex-direction: row;
      margin-bottom: 32px;
      min-height: 65px;
      width: 100%;
      &::after {
        content: "";
        width: 100%;
      }
      span {
        width: 100%;
        a {
          //display: flex;
          align-items: center;
          &.hide{
            display: none;
          }
          img {
            margin-left: 8px;
            width: 16px;
            &#instagram {
              width: 32px;
              height: 32px;
            }
          }
        }
      }
    }
    #bottom-line {
      display: flex;
      width: 100%;
      a {
        width: calc(100%);
        font-weight: 200;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  a {
    color: #FFF;
  }
}

@media screen and (max-width: 1200px) {
  footer {
    flex-direction: column;
    padding: 32px;
    #branding {
      width: 100%;
      margin-bottom: 50px;
      margin-left: 0;
      img {
        height: 31px;
        margin-bottom: 16px;
      }
    }
    nav {
      a {
        width: 100%;
        height: 24px;
      }
      #top-line {
        flex-direction: column;
        width: unset;
        max-height: 0px;
        margin-bottom: 50px;
        &::after {
          content: none;
        }
        span {
          display: flex;
          flex-direction: column;
          margin-bottom: 32px;
        }
      }
      #bottom-line {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

@media screen and (max-width:940px) {
  footer{
    padding-bottom:calc(71px + 32px);
  }
}
</style>
