const { ref } = require("@vue/reactivity")

export const useToggle = () => {
  const state = ref(true);

  const toggle = () => {
    state.value = !state.value
  }

  return { state, toggle }
}
