<template>
  <div class="sticky-container" v-if="forLife">
    <template v-for="number in 10" :key="number">
      <div
        class="photo-sticky"
        v-if="media['for_life_' + [number - 1]] !== null"
      >
        <div class="photo">
          <span v-if="forLife['slider_text_' + [number - 1]] !== null">
            {{ forLife["slider_text_" + [number - 1]] }}
          </span>
          <img
            :src="media['for_life_' + [number - 1]]"
            v-if="media['for_life_' + [number - 1]] !== null"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { ref } from "@vue/runtime-core";
import axios from "axios";
import isMobile from "is-mobile";
export default {
  setup() {
    const media = ref();
    const forLife = ref();
    const isMobileDevice = isMobile();
    async function loadContent() {
      const result = await axios.get(
        `${process.env.VUE_APP_API}/page-contents/brand-platform`,
        {
          headers: {
            Authorization: "Bearer B5p3PKmh86DJ79Y0SCa88OEpiUeYJZKcP4Ajt6vdKH9",
          },
        }
      );
      forLife.value = result?.data?.data?.config?.for_life;
      media.value = result?.data?.data?.config?.media;
    }

    loadContent();

    return { media, isMobileDevice, forLife };
  },
};
</script>

<style lang="scss" scoped>
.sticky-container {
  display: flex;
  flex-direction: column;
  grid-column: 1/6;
  grid-row: 10;
  margin: 50px 0 100px 0;
  .photo-sticky {
    position: sticky;
    width: 100%;
    top: 100px;
    @media screen and (max-width: 1200px) {
      position: initial;
      width: 100vw;
      margin-top: -4px;
    }
    .photo {
      position: relative;
      margin-left: -130px;
      margin-right: -110px;
      @media screen and (max-width: 1200px) {
        margin-left: -20px;
        margin-right: -25px;
      }

      span {
        position: absolute;
        top: 100px;
        left: 130px;
        font-size: 100px;
        line-height: 97px;
        color: var(--lighter);
        padding: 0;
        @media screen and (max-width: 1200px) {
          font-size: 45px;
          line-height: 55px;
          left: 20px;
        }
      }
      img {
        aspect-ratio: 16/9;
        width: -webkit-fill-available;
        @media screen and (max-width: 1200px) {
          height: 812px;
          object-fit: cover;
          width: 100%;
        }
      }
    }
  }
}
</style>
