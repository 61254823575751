<template>
  <div class="component-tab">
    <div class="tabs">
      <div
        v-for="(tab, key) in tabs"
        :key="`tab-${key}`"
        :class="{ tab: true, current: currentIndex === key }"
        @click="changeTab(currentIndex, key)"
      >
        {{ tabs[key] }}
      </div>
    </div>
    <div class="tab-container">
      <!-- <img :src="current" /> -->
      <MediaReader :id="id" :media="{ player_html1: current }" v-if="current"></MediaReader>
    </div>
  </div>
</template>

<script>
import { watch, ref } from "@vue/runtime-core";
import MediaReader from "@/components/organisms/MediaReader.vue";

export default {
  name: "TabComponent",
  components: { MediaReader },
  props: {
    id: Number,
    config: Object,
    ctype: { required: true, type: Number }
  },
  setup(props) {
    const tabs = ref([]);
    const medias = ref([]);
    const current = ref();
    const currentIndex = ref(0);

    watch(
      () => props,
      () => {
        const { label1, label2, label3 } = props?.config;
        const { image1, image2, image3 } = props?.config?.media;
        tabs.value = [label1, label2, label3].filter((e) => e);
        medias.value = [image1, image2, image3].filter((e) => e);
        current.value = medias.value[0];
      },
      { immediate: true }
    );

    function changeTab(prev, next) {
      currentIndex.value = next;
      const elements = document.getElementsByClassName("tab");
      elements[prev].classList.remove("current");
      elements[next].classList.add("current");
      current.value = medias.value[next];
    }

    return { tabs, medias, current, currentIndex, changeTab };
  },
};
</script>

<style lang="scss" scoped>
.component-tab {
  padding: 0px 32px;
  box-sizing: border-box;
  .tabs {
    display: flex;
    margin-bottom: 32px;
    box-sizing: border-box;
    .tab {
      margin-right: 10%;
      font-weight: 700;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      &::after {
        content: "";
        margin-top: 8px;
        height: 4px;
        width: 0%;
        background-color: var(--darker);
        transition: all 0.5s ease-in-out;
      }
      &.current,
      &:hover {
        &::after {
          width: 100%;
        }
      }
    }
  }
  .tab-container {
    min-height: 50vh;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-sizing: border-box;
    img {
      width: 100%;
    }
  }
}
</style>
