<template>
  <div class="write-something">
    <div class="controls">
      <div class="control">
        <div class="label">size</div>
        <input type="range" min="75" max="300" :value="fontSize" @input="calcFontSize"/>
      </div>
      <div class="control">
        <div class="label">line height</div>
        <input type="range" min="0" max="300"  :value="lineHeight" @input="calcLineHeight"/>
      </div>
    </div>
    <div class="editable" contenteditable :style="{ 'line-height': `${lineHeight}%`, 'font-size': `${fontSize}%` }">
      write something &amp; try sizes
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';

export default {
  name: "WriteSomething",
  setup () {
    const lineHeight = ref(75);
    const fontSize = ref(125);

    const calcLineHeight = (e) => {
      lineHeight.value = e.target.value;
    }

    const calcFontSize = (e) => {
      fontSize.value = e.target.value;
    }

    return { calcLineHeight, calcFontSize, lineHeight, fontSize }
  }
}
</script>

<style lang="scss" scoped>

.write-something {
  display: flex;
  flex-flow: column;
  margin: 32px;
  .controls {
    display: flex;
    justify-content: space-around;
    height: 50px;
    border: solid var(--darker) 1px;
    .control:first-of-type {
      border-right: solid var(--darker) 1px;
    }
    .control {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 12px;
      width: 50%;
      input {
        padding: 0px 0px 0px 20px;
      }
    }
  }
  .editable {
    font: normal normal normal 21px/21px NouvelR;
    outline: 0px solid transparent;
    border-right: solid var(--darker) 1px;
    border-left: solid var(--darker) 1px;
    border-bottom: solid var(--darker) 1px;
    min-height: 30vh;
    padding: 5% 0px 0px 5%;
  }
}

input[type="range"] {
    height: 31px;
    -webkit-appearance: none;
    flex-grow: 1;
    box-sizing: border-box;
    // margin: 64px 0;
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px var(--darker);
    background: var(--darker);
    border-radius: 1px;
    border: 0px solid var(--darker);
  }
  input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px var(--darker);
    border: 1px solid var(--darker);
    height: 16px;
    width: 16px;
    border-radius: 23px;
    background: var(--darker);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7.5px;
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: var(--darker);
  }
  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px var(--darker);
    background: var(--darker);
    border-radius: 1px;
    border: 0px solid var(--darker);
  }
  input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 0px var(--darker);
    border: 1px solid var(--darker);
    height: 16px;
    width: 16px;
    border-radius: 23px;
    background: var(--darker);
    cursor: pointer;
  }
  input[type="range"]::-ms-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type="range"]::-ms-fill-lower {
    background: var(--darker);
    border: 0px solid var(--darker);
    border-radius: 2px;
    box-shadow: 0px 0px 0px var(--darker);
  }
  input[type="range"]::-ms-fill-upper {
    background: var(--darker);
    border: 0px solid var(--darker);
    border-radius: 2px;
    box-shadow: 0px 0px 0px var(--darker);
  }
  input[type="range"]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px var(--darker);
    border: 1px solid var(--darker);
    height: 24px;
    width: 24px;
    border-radius: 23px;
    background: var(--darker);
    cursor: pointer;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: var(--darker);
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: var(--darker);
  }

  @media screen and (max-width: 600px) {
    .write-something {
      .controls {
        height: 70px;
        .control {
          flex-flow: column;
          justify-content: space-between;
          input {
            padding: unset;
            max-width: -webkit-fill-available;
            max-width: -moz-available;
            max-width: stretch;
          }
        }
      }
    }
  }
</style>
