<template>
  <div class="move-weight">
    <div
      v-for="(weight, key) in weights"
      @click="setClicked(weight)"
      :key="key"
      class="card"
      :class="{ [weight]: true, clicked: clicked === weight }"
    >
      {{ weight }}
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import isMobile from "is-mobile";

export default {
  name: "MoveWeight",
  setup() {
    const weights = [
      "light",
      "book",
      "regular",
      "semibold",
      "bold",
      "extrabold",
    ];
    const clicked = ref();

    function setClicked(weight) {
      if (isMobile()) {
        clicked.value = weight;
      }
    }

    return { weights, clicked, setClicked };
  },
};
</script>

<style lang="scss" scoped>
.move-weight {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 50vh;
  position: relative;
   margin: 64px 0;
  .card {
    transition: all 1s ease-in-out;
    border: 1px solid var(--darker);
    border-left: none;
    flex-grow: 1;
    padding: 32px 16px;
    font-size: 21px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    &::after {
      content: "r";
      position: absolute;
      top: 0;
      right: 0;
      top: 50%;
      font-size: 16em;
      transform: translate(50%, -50%);
      transition: all 1s ease-in-out;
    }
    &.clicked {
      flex-grow: 2;
      &::after {
        right: 50%;
      }
    }
    &:hover {
      flex-grow: 2;
      &::after {
        right: 50%;
      }
    }
    &.light {
      font-weight: 200;
    }
    &.book {
      font-weight: 300;
    }
    &.regular {
      font-weight: 400;
    }
    &.semibold {
      font-weight: 500;
    }
    &.bold {
      font-weight: 700;
    }
    &.extrabold {
      font-weight: 700;
    }
  }
}
@media screen and (max-width: 780px) {
  .move-weight {
    .card {
      flex-grow: 0;
      &:first-child {
        flex-grow: 1;
      }
      &:hover {
        width: 1000%;
      }
    }
    &:hover {
      .card {
        &:first-child {
          flex-grow: 0;
        }
      }
    }
  }
}
</style>
