<template>
  <div class="showcase-page">
    <div class="showcase-all" v-if="showcasesLeft && showcasesRight && merged">
      <div class="showcase-container" v-if="isMobileDevice">
        <div
          class="showcase"
          :style="'grid-column:1;grid-row:'+ (index + 1)"
          v-for="(showcase, index) in merged"
          :key="index"
        >
          <div class="title">
            <h2 v-if="showcase.is_h2 == 1"><span v-for="(test, key) in chunkString(showcase.title, 24)" :key="key" >{{test}}</span></h2>
            
            <h3 v-else><span v-for="(test, key) in chunkString(showcase.title, 24)" :key="key" >{{test}}</span></h3>
            <learn-more
              v-if="showcase.modal_text"
              :credits="showcase.credit"
              :text="showcase.modal_text"
              :title="showcase.title"
              @showModal="showModal"
            ></learn-more>
          </div>
          <div class="illust">
            <template v-if="checkURL(showcase.file) === true">
              <img :src="showcase.file" />
            </template>
            <template v-if="checkURL(showcase.file) === false">
              <video
                :id="`video-player-${showcase.id}`"
                :poster="showcase.file_cover"
                class="video"
                preload="metadata"
              >
                <source type="video/mp4" :src="showcase.file" />
              </video>
              <span
                @click="toggle(showcase.id)"
                class="controle"
                :class="{ hidden: hidden, play: !playing, pause: playing }"
              ></span>
            </template>
          </div>
        </div>
      </div>
      <div class="showcase-container -scrollleft" id="scrollleft" v-if="!isMobileDevice">
        <div
          class="showcase"
          :style="'grid-column:1;grid-row:'+ (index + 1)"
          v-for="(showcase, index) in showcasesRight"
          :key="index"
        >
          <div class="title">
            <h2 v-if="showcase.is_h2 == 1"><span v-for="(test, key) in chunkString(showcase.title, 24)" :key="key" >{{test}}</span></h2>

            <h3 v-else><span v-for="(test, key) in chunkString(showcase.title, 24)" :key="key" >{{test}}</span></h3>
            <learn-more
              v-if="showcase.modal_text"
              :credits="showcase.credit"
              :text="showcase.modal_text"
              :title="showcase.title"
              @showModal="showModal"
            ></learn-more>
          </div>
          <div class="illust">
            <template v-if="checkURL(showcase.file) === true">
              <img :src="showcase.file" />
            </template>
            <template v-if="checkURL(showcase.file) === false">
              <video
                :id="`video-player-${showcase.id}`"
                :poster="showcase.file_cover"
                class="video"
                preload="metadata"
              >
                <source type="video/mp4" :src="showcase.file" />
              </video>
              <span
                @click="toggle(showcase.id)"
                class="controle"
                :class="{ hidden: hidden, play: !playing, pause: playing }"
              ></span>
            </template>
          </div>
        </div>
      </div>

      <div class="showcase-container -scrollright" id="scrollright" v-if="!isMobileDevice">
        <div
          class="showcase"
          :style="'grid-column:2;grid-row:'+ (index + 1)"
          v-for="(showcase, index) in showcasesLeft"
          :key="index"
        >
          <div class="title">
            <h2 v-if="showcase.is_h2 == 1"><span v-for="(test, key) in chunkString(showcase.title, 24)" :key="key" >{{test}}</span></h2>
            <h3 v-else><span v-for="(test, key) in chunkString(showcase.title, 24)" :key="key" >{{test}}</span></h3>
            <learn-more
              v-if="showcase.modal_text"
              :credits="showcase.credit"
              :text="showcase.modal_text"
              :title="showcase.title"
              @showModal="showModal"
            ></learn-more>
          </div>
          <div class="illust">
            <template v-if="checkURL(showcase.file) === true">
              <img :src="showcase.file" />
            </template>
            <template v-if="checkURL(showcase.file) === false">
              <video
                :id="`video-player-${showcase.id}`"
                :poster="showcase.file_cover"
                class="video"
                preload="metadata"
              >
                <source type="video/mp4" :src="showcase.file" />
              </video>
              <span
                @click="toggle(showcase.id)"
                class="controle"
                :class="{ hidden: hidden, play: !playing, pause: playing }"
              ></span>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/runtime-core";
import axios from "axios";
import LearnMore from "@/components/organisms/LearnMore.vue";
import isMobile from "is-mobile";
export default {
  name: "Showcase",
  components: {
    LearnMore,
  },
  setup() {
    const showcasesLeft = ref();
    const showcasesRight = ref();
    const merged = ref();
    const isMobileDevice = isMobile();
    async function loadContent() {
      const result = await axios.get(`${process.env.VUE_APP_API}/showcases`, {
        headers: {
          Authorization: "Bearer B5p3PKmh86DJ79Y0SCa88OEpiUeYJZKcP4Ajt6vdKH9",
        },
      });
      showcasesLeft.value = result?.data?.data[1].reverse();
      showcasesRight.value = result?.data?.data[0];
      merged.value  = showcasesLeft.value
                .filter(e => showcasesRight.value.find(a2e => a2e.id === e.id) === undefined)
                .concat(showcasesRight.value)
                .sort((a, b) => parseInt(b.id) - parseInt(a.id));

    }
    loadContent();

    //remove style bug modal
    const showTest = ref(false);
    function showModal(val) {
      showTest.value = val
      if(val === true )document.querySelector('.showcase-container.-scrollleft').style.transform = 'none';
       if(val === false )document.querySelector('.showcase-container.-scrollleft').style.removeProperty('transform')
    }

    function checkURL(url) {
      return(url.match(/\.(jpeg|jpg|gif|png)$/) != null);
    }
    const playing = ref(false);

    function toggle(id) {
      const video = document.getElementById(`video-player-${id}`);
      if (playing.value) video.pause();
      else video.play();
      playing.value = !playing.value;
    }

    function chunkString(str, length) {
      return str.match(new RegExp('.{1,' + length + '}', 'g'));
    }

    var mousewheelevt=(/Firefox/i.test(navigator.userAgent))? "DOMMouseScroll" : "mousewheel";
    var target = document.querySelector('body');
    let newPos = 0;
    let newPos2 = 0;

    if (target.attachEvent)
      target.attachEvent("on"+mousewheelevt, function(e){scroller(e)});
    else if (target.addEventListener)
      target.addEventListener(mousewheelevt, function(e){scroller(e)},false);

    function scroller(evt) {

      if(window.innerWidth < 1200) {
        document.querySelector('#app').style.position = 'relative';
        return false;
      }
      //Guess the delta.
      let delta = 0;
      if (!evt) evt = window.event;
      if (evt.wheelDelta) {
        delta = evt.wheelDelta/120;
      } else if (evt.detail) {
        delta = -evt.detail/3;
      }
      newPos += delta;
      if( newPos <= -90 ) newPos = -90;
      if( newPos >= 0 ) newPos = 0;
      newPos2 = newPos * -1;

      if( window.location.pathname === '/showcase') {
        document.querySelector('.showcase-container.-scrollright').style.top = newPos + '%';
        document.querySelector('.showcase-container.-scrollleft').style.top = newPos2 + '%';
      }

       if( window.location.pathname === '/showcase') {
        if( newPos == -90 ) {
          document.querySelector('#app').style.position = 'relative';
        } else if( document.querySelector('body').scrollTop == 0  ) {
          document.querySelector('#app').style.position = 'fixed';
        }
       }
    }

    return { showcasesLeft, showcasesRight, checkURL, playing, toggle, chunkString, showModal, merged, isMobileDevice };
  },
};
</script>

<style lang="scss" scoped>
#app {
  position: fixed;
}

.showcase-page {
  display: flex;
  flex-direction: column;
  padding: 64px 130px 0;
  padding-top: 100px;
  min-height: 100vh;
  width: 100%;
  background-color: var(--lighter);
  box-sizing: border-box;
  max-height: 100vh;
  @media screen and (max-width: 1200px) {
    max-height: initial;
    padding: 0 20px;
    padding-top: 131px;
    padding-left: 15vw;
    padding-right: 15vw;
  }
  @media screen and (max-width: 991px) {
    padding-left: 10vw;
    padding-right: 10vw;
  }
  @media screen and (max-width: 768px) {
    padding-left: 5vw;
    padding-right: 5vw;
    margin-bottom: 50px;
  }
}
h1 {
  margin-block-start: 0;
  margin-block-end: 0;
  z-index: -1;
  @media screen and (max-width: 1200px) {
    padding-top: 0;
  }
}
.showcase-all {
  display: grid;
  grid-template-columns: minmax(auto, 539px) minmax(auto, 763px);
  grid-template-rows: auto;
  gap: 0 10%;
  height: 100%;
  overflow: hidden;
  gap: 0 20%;
  @media screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column-reverse;
    overflow: auto;
    height: auto;
  }
}
.showcase-container {
  display: grid;
  grid-column: auto;
  grid-gap: 530px 0;
  position: relative;
  @media screen and (max-width: 1200px) {
    width: 100%;
    grid-gap: 100px 0;
  }
  &.-scrollleft {
    margin-top: 150px;
    grid-column: 1;
    transform: translateY(calc(-100% + 100vh + 5px));
    @media screen and (max-width: 1200px) {
      transform: none;
      margin-top: 100px;
    }
  }
  &.-scrollright {
    //height: 100vh;
     grid-column: 2;
     margin-top: 70px;
    @media screen and (max-width: 1200px) {
      grid-column: 1;
      margin-top: 0;
    }
  }
  .showcase {
    display: flex;
    flex-direction: column;
    max-height: 739px;

    .title {
      display: flex;
      align-items: flex-end;
      margin-bottom: 20px;
      @media screen and (max-width: 1200px) {
        flex-direction: column;
        align-items: flex-start;
      }
      h2,
      h3 {
        margin-block-start: 0;
        margin-block-end: 0;
      }
      h2 {
        font-size: 36px;
        line-height: 34px;
        max-width: 75%;
        @media screen and (max-width: 1200px) {
          font-size: 24px;
          line-height: 24px;
        }
        span {
          padding: 0;
        }
      }
      h3 {
        font-size: 21px;
        line-height: 34px;
        @media screen and (max-width: 1200px) {
          font-size: 16px;
        }
         span {
          padding: 0;
        }
      }
    }

    .illust {
      position: relative;
      img, video {
        max-width: 100%;
         max-height: 739px;
        @media screen and (max-width: 1200px) {
          width: 100%;
        }
      }
    }
  }
}

.controle {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 60px;
    background-color: white;
    margin: 0;
    border-radius: 0;
    
    cursor: pointer;
    &::before {
      position: absolute;
      content: "";
      display: inline-block;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
    }
    &.play {
      &::before {
        height: 0;
        width: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 14px solid #000;
      }
      &::after {
        content: "watch";
        color: var(--darker);
        filter: invert(1);
        background-color: transparent;
        position: absolute;
        right: 0;
        top: 45%;
        padding-left: 16px;
        font-size: 21px;
        font-weight: 700;
        transform: translate(100%, -50%);
      }
    }
    &.pause {
      &::before {
        width: 4px;
        height: 16px;
        // border: 0px solid var(--darker);
        border-left: 4px solid #000;
        border-right: 4px solid #000;
        border-top: 0px solid transparent;
        border-bottom: 0px solid transparent;
      }
      &::after {
        content: "pause";
        color: var(--darker);
        filter: invert(1);
        background-color: transparent;
        position: absolute;
        right: 0;
        top: 45%;
        padding-left: 16px;
        font-size: 21px;
        font-weight: 700;
        transform: translate(100%, -50%);
      }
    }
    &.hidden {
      display: none;
    }
  }
.video {
  object-fit: cover;
}
body.dark-mode {
  .play {
    &::after {
      filter: invert(0);
    }
  }
}
</style>
