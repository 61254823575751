<template>
  <div>
  <div class="inverter" :class="{ inverted: isInverted }">
    <button @click="handleIntervert" :class="{ inverted: isInverted }">
      intervert color
    </button>
    <img :src="media.image1 ? media.image1 : '../../assets/logo-renault-white.svg'" alt="renault logo" />
  </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  name: "InterVertColor",
  props: {
    media: Object
  },
  setup() {
    const isInverted = ref(false);

    function handleIntervert() {
      isInverted.value = !isInverted.value;
    }
    return { isInverted, handleIntervert };
  },
};
</script>

<style lang="scss" scoped>
.inverter {
  @include media-container;
  position: relative;
  padding-top: 56.25%;
  background-color: var(--darker);
   @media screen and (max-width: 1200px) {
     background-color: transparent;
     padding-top: 0;

   }

  &.inverted {
    filter: invert(1);
  }

  @media screen and (max-width: 1200px) {
    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: (812 / 375) * 100%;
    }
  }

  button {
    position: absolute;
    background-color: white;
    color: #000;
    left: 0;
    top: 0;
    margin: 32px 32px;
    padding: 11px 14px;
     border: 2px solid  var(--lighter);
    &.inverted {
      &::after {
        background-color: white;
      }
      &:hover {
        &::after {
          border: 2px solid var(--lighter);
        }
      }
    }
    &:after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid #000;
      margin-left: 10px;
    }
    &:hover {
      &::after {
        background-color: var(--darker);
      }
    }
  }
  img {
    position: absolute;
    top: 33%;
    padding: 0;
    max-height: 50%;
    object-fit: cover;
    width: auto;
     @media screen and (max-width: 500px){
      object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    width: -webkit-fill-available;
    z-index: 1;
     }
  }
}
</style>
